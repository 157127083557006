import React, { useRef, useState } from "react";
import { CiBadgeDollar } from "react-icons/ci";
import { AiOutlineFileAdd } from "react-icons/ai";
import { SiGooglemarketingplatform } from "react-icons/si";
import {
  MdHomeRepairService,
  MdOutlineManageAccounts,
  MdOutlinePriceChange,
} from "react-icons/md";
import { GiTie } from "react-icons/gi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { GoBriefcase } from "react-icons/go";
import { TbReportSearch } from "react-icons/tb";
import { FaSearchengin } from "react-icons/fa";
import { useSelector } from "react-redux";
import { ModuleAccordian } from "../ModuleAccordian/ModuleAccordian";
import "./Sidebar.css";
import brandLogo from "../../assets/images/01.png";
import { Link } from "react-router-dom";

const routesWithPermisions = [
  {
    permission: "LEAD_MANAGEMENT",
    moduleName: "Lead Managemnet",
    icon: <AiOutlineFileAdd />,
    links: [
      { path: "track", title: "CRM Track", rolesAllowed: ["AdminDm", "SuperAdmin"] },
      { path: "manage", title: "Manage", rolesAllowed: ["AdminDm", "SuperAdmin"] },
      {
        path: "salespipeLine",
        title: "Sales PipeLine",
        rolesAllowed: ["AdminDm", "Telecaller", "Counselor", "SuperAdmin"],
      },
      {
        path: "registration",
        title: "Registration",
        rolesAllowed: ["AdminDm", "Telecaller", "Counselor", "SuperAdmin"],
      },
      {
        path: "admission",
        title: "Admission",
        rolesAllowed: ["AdminDm", "Telecaller", "Counselor", "SuperAdmin"],
      },

      {
        path: "archive",
        title: "Archive",
        rolesAllowed: ["AdminDm", "SuperAdmin"],
      },
    ],
  },

  // {
  //   permission: "SALES_MANAGEMENT",
  //   icon: <MdOutlinePriceChange />,
  //   moduleName: "Sales Automation",
  //   links: [
  //     { path: "salesquotes", title: "Sales Quotes", rolesAllowed: ["AdminDm", "SuperAdmin"] },
  //   ],
  // },
  // {
  //   permission: "MARKETING_MANAGEMENT",
  //   icon: <SiGooglemarketingplatform />,
  //   moduleName: "Marketing Automation",
  //   links: [
  //     { path: "", title: "Customer Inquries", rolesAllowed: ["AdminDm", "SuperAdmin"] },
  //   ],
  // },
  // {
  //   permission: "CUSTOM_SERVICES",
  //   icon: <MdHomeRepairService />,
  //   moduleName: "Customer Service",
  //   links: [
  //     { path: "complaints", title: "Complaints", rolesAllowed: ["AdminDm", "SuperAdmin"] },
  //   ],
  // },
  // {
  //   permission: "DOC_MANAGEMENT",
  //   icon: <IoDocumentTextOutline />,
  //   moduleName: "Document Management",
  //   links: [
  //     {
  //       path: "customerdocuments",
  //       title: "Customer Documents",
  //       rolesAllowed: ["AdminDm"],
  //     },
  //     { path: "contracts", title: "Contracts", rolesAllowed: ["AdminDm", "SuperAdmin"] },
  //     { path: "files", title: "Files", rolesAllowed: ["AdminDm", "SuperAdmin"] },
  //   ],
  // },
  // {
  //   permission: "TRANING",
  //   icon: <MdOutlineManageAccounts />,
  //   moduleName: "Training Management",
  //   links: [
  //     {
  //       path: "studentaccount",
  //       title: "Student Account",
  //       rolesAllowed: ["AdminDm", "SuperAdmin"],
  //     },
  //     {
  //       path: "attendancetracking",
  //       title: " Attendance Tracking",
  //       rolesAllowed: ["AdminDm", "SuperAdmin"],
  //     },
  //     {
  //       path: "coursesstructure",
  //       title: "Courses Structure",
  //       rolesAllowed: ["AdminDm", "SuperAdmin"],
  //     },
  //     {
  //       path: "gradesandprogressreport",
  //       title: "Grades & Reports",
  //       rolesAllowed: ["AdminDm", "SuperAdmin"],
  //     },
  //     {
  //       path: "timetableandschedule",
  //       title: "Time Table & Schedule",
  //       rolesAllowed: ["AdminDm", "SuperAdmin"],
  //     },
  //     {
  //       path: "eventcalender",
  //       title: "EventCalender",
  //       rolesAllowed: ["AdminDm", "SuperAdmin"],
  //     },
  //     {
  //       path: "feemanagement",
  //       title: "Fee Management",
  //       rolesAllowed: ["AdminDm", "SuperAdmin"],
  //     },
  //     {
  //       path: "studentaccesscontrol",
  //       title: "Student Access Control",
  //       rolesAllowed: ["AdminDm", "SuperAdmin"],
  //     },
  //   ],
  // },
  {
    permission: "HR_MANAGEMENT",
    icon: <GoBriefcase />,
    moduleName: "HR Management",
    links: [{ path: "registrations", title: "Registration", rolesAllowed: ["AdminDm","SuperAdmin", "HrManager", "HrExecutive"] },
    { path: "employeprofile", title: "Employe Profile", rolesAllowed: ["AdminDm","SuperAdmin", "HrManager", "HrExecutive"] },
    { path: "employeattendance", title: "Attendance Tracking", rolesAllowed: ["AdminDm","SuperAdmin", "HrManager", "HrExecutive"] },
    { path: "leavemanagemnet", title: "Leave Management", rolesAllowed: ["AdminDm","SuperAdmin", "HrManager", "HrExecutive"] },
    { path: "timetracking", title: "Time Tracking", rolesAllowed: ["AdminDm","SuperAdmin","HrManager", "HrExecutive"] },
    { path: "payrollmanagement", title: "Payroll Canculator", rolesAllowed: ["AdminDm","SuperAdmin", "HrManager", "HrExecutive"] },
    { path: "documentmanagement", title: "Documents Management", rolesAllowed: ["AdminDm","SuperAdmin", "HrManager", "HrExecutive"] },
    ]
  },
  // {
  //   permission: "FINANCE_MANAGEMENT",
  //   icon: <CiBadgeDollar />,
  //   moduleName: "Finance Management",
  //   links: [
  //     { path: "#", title: "Basic Accounts", rolesAllowed: [ "SuperAdmin"] },
  //     { path: "#", title: "Sale", rolesAllowed: [ "SuperAdmin"] },
  //     { path: "#", title: "Purchase", rolesAllowed: ["SuperAdmin"] },
  //     { path: "#", title: "Report", rolesAllowed: [ "SuperAdmin"] },
  //   ],
  // },

  {
    permission: "REPORTNIG",
    icon: <TbReportSearch />,
    moduleName: "Reporting",
    links: [
      {
        path: "reporting",
        title: "Reporting",
        rolesAllowed: ["AdminDm", "SuperAdmin",],
      },
      // { path: "employeprofile", title: "Employe Profile", rolesAllowed: ["AdminDm", "HrManager", "HrExecutive"] },
    ],
  },


  {
      permission: "REPORTING_ATS",
    icon: <FaSearchengin />,
    moduleName: "ATS",
    links: [
      {
        path: "atstrack", title: "ATS Track", 
        rolesAllowed: ["AdminEM", "SuperAdmin", "HrManager", "Recruiter", "HrExecutive"],
      },
      {
        path: "candidatemanagement", title: "Candidate Management", 
        rolesAllowed: ["AdminEM", "SuperAdmin", "HrManager", "Recruiter", "HrExecutive"],
      },
      {
        path: "collabrationtool", title: "Company Management", 
        rolesAllowed: ["AdminEM", "SuperAdmin","HrManager", "Recruiter", "HrExecutive"],
       },

       {
        path: "vacancymanagement", title: "Vacancy Management", 
        rolesAllowed: ["AdminEM", "SuperAdmin","HrManager", "Recruiter", "HrExecutive"],
       },
       
       {
       path: "interviewscheduling", title: "Interview Scheduling", 
       rolesAllowed: ["AdminEM", "SuperAdmin","HrManager", "Recruiter", "HrExecutive"],
       },
       {
        path: "candidateonboarding", title: "Candidate Onboarding", 
        rolesAllowed: ["AdminEM", "SuperAdmin","HrManager", "Recruiter", "HrExecutive"],
       },
       {
        path: "atsreports", title: "Reports", 
        rolesAllowed: ["AdminEM", "SuperAdmin","HrManager", "Recruiter", "HrExecutive"],
      },
      // { path: "employeprofile", title: "Employe Profile", rolesAllowed: ["AdminDm", "HrManager", "HrExecutive"] },
    ],
  },

  

  // {
  //   permission: "TRANING_MANAGEMENT",
  //   icon: <TbReportSearch />,
  //   moduleName: " ",
  //   links: [{ path: "", title: "" }],
  // },
];

const Sidebar = () => {
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const userPermisionList = data?.role?.PlaybleModule;
  const userPermisionList2 = data?.role?.Nonplayble_module;
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
console.log(data);
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion "
  );

  const changeStyle = () => {
    setStyle((prevStyle) =>
      prevStyle ===
      "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        ? "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
        : "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    );
  };

  const changeStyle1 = () => {
    if (
      style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };

  return (
    <div className="bg-warning">
      <div id="wrapper" style={{ height: `${windowSize.current[1]}px` }}>
        <ul
          className={style}
          id="accordionSidebar"
          style={{ overflow: "scroll", overflowX: "hidden" }}
        >
          <Link
            className="sidebar-brand d-flex align-items-center justify-content-center "
            to="#"
          >
            <div className="mx-1 sidebar-brand-text">
              <span className="fs-6">Mantras ERP</span>
            </div>
            <div className="d-md-inline">
              <button
                className="rounded-circle border-0 me-2 mt-3"
                id="sidebarToggle"
                onClick={changeStyle}
              ></button>
            </div>
          </Link>
          <hr className="sidebar-divider my-0" />
          <li className="nav-item active">
            <Link className="nav-link" to="/">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          

          {routesWithPermisions?.map((obj, index) => {
            return (
              userPermisionList !== undefined &&
              JSON.parse(userPermisionList)?.map((userPermission) => {
                if (obj?.permission === userPermission?.permission) {
                  return (
                    <ModuleAccordian
                      data={{ ...obj, index }}
                      currentRole={data?.role?.roleName}
                    />
                  );
                } else {
                  userPermisionList2 !== undefined &&
                    JSON.parse(userPermisionList2)?.map((userPermission) => {
                      if (obj?.permission === userPermission?.permission)
                        return (
                          <ModuleAccordian
                            data={{ ...obj, index }}
                            currentRole={data?.role?.roleName}
                          />
                        );
                    });
                }
              })
            );
          })}

          {/* <li className="nav-item">
            <Link className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities14"
              aria-expanded="true" aria-controls="collapseUtilities">
              <TbReportSearch />
              <span style={{ marginLeft: "4px" }}>Reporting</span>
            </Link>
            <div id="collapseUtilities14" className="collapse" aria-labelledby="headingUtilities"
              data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <Link className="collapse-item" href="/reporting">Reporting</Link>
              </div>
            </div>
          </li> */}
          {/* <li className="nav-item">
            <Link
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseUtilities15"
              aria-expanded="true"
              aria-controls="collapseUtilities"
            >
              <FaSearchengin />
              <span style={{ marginLeft: "4px" }}>A T S</span>
            </Link>
            <div
              id="collapseUtilities15"
              className="collapse"
              aria-labelledby="headingUtilities"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <Link className="collapse-item" href="/resumeparsing">
                  Resume parsing
                </Link>
                <Link className="collapse-item" href="/jobparsing">
                  Job Parsing
                </Link>
                <Link
                  className="collapse-item"
                  to={"/candidatemanagement"}
                  // href="/candidatemanagement"
                >
                  Candidate Management
                </Link>
                <Link className="collapse-item" to="/collabrationtool">
                  Collabration Managment
                </Link>
                <Link className="collapse-item" to="/interviewscheduling">
                  Interview Scheduling
                </Link>
                <Link className="collapse-item" href="/analyticsandreporting">
                  Analytics & Reporting
                </Link>
                <Link className="collapse-item" href="/placements">
                  Placements
                </Link>
              </div>
            </div>
          </li> */}
          {/* <li className="nav-item">
            <Link className="nav-link collapsed" href="#">
              <GiTie />
              <span style={{ marginLeft: "4px" }}>Alumni Accounts</span>
            </Link>
          </li> */}
          <hr className="sidebar-divider" />
          <Link
            className="nav-link collapsed rounded-pill mx-2 text-center"
            href="#"
            onClick={() => window.open("https://techtorch.solutions/")}
          >
            <span class="badge text-bg-light  fw-lighter opacity-75">
              Powered By{" "}
             
              <img
                src={brandLogo}
                className="img-fluid "
                alt="logo"
                width="70px"
              />
            </span>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
