import React, { useEffect, useState } from "react";

import edMantra from "../../services/edMantra";
import ChartComponent from "../../Components/Chart/ChartComponent";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const AtsTrack = () => {
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  console.log(data, "datatatatatatatata");
  const [onboardingCount, setOnboardingCount] = useState(0);
  const [joinedCount, setJoinedCount] = useState(0);
  const [terminatedCount, setTerminatedCount] = useState(0);
  const [workingCount, setWorkingCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [totalCompany, setTotalCompany] = useState(0);
  const [totalInterview, setTotalInterview] = useState(0);
  const [totalOnboarding, setTotalOnboarding] = useState(0);
  const chartData = {
    labels: [
      "Total Candidates",
      "Total Company",
      "Total Interview",
      "Total Onboarding",
      "Joined",
      "Terminated",
      "Working",
      "Rejected",
    ],
    values: [
      totalCandidates || 0, // Ensure fallback to 0 if undefined
      totalCompany || 0,
      totalInterview || 0,
      totalOnboarding || 0,
      joinedCount || 0,
      terminatedCount || 0,
      workingCount || 0,
      rejectedCount || 0,
    ],
  };
  

  // Define functions before useEffect
  const fetchTotalCandidates = async () => {
    try {
      const res = await edMantra.getAllCandidates();
      console.log("Candidates API Response:", res?.data?.data);

      const data = Array.isArray(res?.data?.data) ? res?.data?.data.length : 0;
      setTotalCandidates(data);
    } catch (error) {
      console.error("Error fetching candidates:", error);
      setTotalCandidates(0);
    }
  };

  const fetchTotalCompany = async () => {
    try {
      const res = await edMantra.getAllCompany({ empId: data?.empId });
  
      console.log("Full API Response (Company):", res);
      console.log("Data inside response:", res?.data?.data);
  
      if (Array.isArray(res?.data?.data)) {
        setTotalCompany(res?.data?.data.length);
      } else {
        console.warn("Company API did not return an array!");
        setTotalCompany(0);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
      setTotalCompany(0);
    }
  };
  

  const fetchTotalInterview = async () => {
    try {
      const res = await edMantra.getAllScheduledInterview({ empId: data?.empId });
      console.log("Interview API Response:", res?.data?.data);
      console.log("Data inside response:", res?.data?.data);
  
      if (Array.isArray(res?.data?.data)) {
        setTotalInterview(res?.data?.data.length);
      } else {
        console.warn("Interview API did not return an array!");
        setTotalInterview(0);
      }
     
    } catch (error) {
      console.error("Error fetching interviews:", error);
      setTotalInterview(0);
    }
  };

  const fetchTotalOnboarding = async () => {
    try {
      const res = await edMantra.getCandidatebyStatus({ empId: data?.empId });
      console.log("Data inside response:", res?.data?.data);
  
      if (Array.isArray(res?.data?.data)) {
        setTotalOnboarding(res?.data?.data.length);
      } else {
        console.warn("Onboarding API did not return an array!");
        setTotalOnboarding(0);
      }
    } catch (error) {
      console.error("Error fetching onboarding data:", error);
      setTotalOnboarding(0);
    }
  };

  const totalOnboardingByStatus = async () => {
    try {
      const res = await edMantra.getCandidatebyStatus({ empId: data?.empId });
  
      console.log("Onboarding API Response:", res);
      console.log("Filtered Data:", res?.data?.data);
  
      if (Array.isArray(res?.data?.data)) {
        const filteredData = res.data.data;
  
        setJoinedCount(filteredData.filter(item => item.candidateStatus === "Joined").length);
        setTerminatedCount(filteredData.filter(item => item.candidateStatus === "Terminated").length);
        setWorkingCount(filteredData.filter(item => item.candidateStatus === "Working").length);
        setRejectedCount(filteredData.filter(item => item.candidateStatus === "Rejected").length);
      }
    } catch (error) {
      console.error("Error fetching onboarding candidates:", error);
    }
  };
  // Call functions inside useEffect
  useEffect(() => {
    fetchTotalCandidates();
    fetchTotalCompany();
    fetchTotalInterview();
    fetchTotalOnboarding();
  }, []); // Runs once when the component mounts

  // ✅ Debugging UI Update
  useEffect(() => {
    console.log("Updated allInterview State:", totalInterview);
  }, [totalInterview]);

  useEffect(() => {
    console.log("Updated allOnboarding State:", totalOnboarding);
  }, [totalOnboarding]);

  useEffect(() => {
    totalOnboardingByStatus();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2 mb-2">
          <a href="Manage" style={{ textDecoration: "none" }}>
            {" "}
            <div
              className="card border shadow h-100 py-2"
              style={{ backgroundColor: "rgba(177, 156, 217, 0.9)" }}
            >
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="h6 text-xs font-weight-bold text-black text-uppercase mb-2">
                      Total Candidates
                    </div>
                    <div className="h1 mb-0 font-weight-bold text-black">
                    {totalCandidates}
                      <i className="fas fa-user text-black"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-2 mb-2">
          <Link
            to={{ pathname: "/Manage", search: "?status=Close" }}
            style={{ textDecoration: "none" }}
          >
            {" "}
            <div
              className="card border shadow h-100 py-2"
              style={{ backgroundColor: "rgba(75, 192, 192, 0.9)" }}
            >
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="h6 text-xs font-weight-bold text-black text-uppercase mb-2">
                      Total Company
                    </div>
                    <div className="h1 mb-0 font-weight-bold text-black">
                      {totalCompany}
                      <i class="fa-solid fa-building"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-md-2 mb-2">
          <a href="Registration" style={{ textDecoration: "none" }}>
            {" "}
            <div
              className="card border shadow h-100 py-2"
              style={{ backgroundColor: "rgba(255, 99, 132, 0.9)" }}
            >
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="h6 text-xs font-weight-bold text-black text-uppercase mb-2">
                      Total Interview
                    </div>
                    <div className="h1 mb-0 font-weight-bold text-black">
                      {totalInterview}

                      <i class="fa-solid fa-clipboard"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-2 mb-2">
          <a href="Admission" style={{ textDecoration: "none" }}>
            {" "}
            <div
              className="card border shadow h-100 py-2"
              style={{ backgroundColor: "rgba(255, 153, 51, 0.9)" }}
            >
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="h6 text-xs font-weight-bold text-black text-uppercase mb-2">
                      Total Onboard
                    </div>
                    <div className="h1 mb-0 font-weight-bold text-black">
                      {totalOnboarding}
                      <i className="fa fa-flag-checkered  text-black"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        {/* <div className="col-md-2 mb-2">
          <a href="Manage" style={{ textDecoration: "none" }}>
            {" "}
            <div
              className="card border shadow h-100 py-2"
              style={{ backgroundColor: "rgba(6, 129, 2, 0.98)" }}
            >
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="h6 text-xs font-weight-bold text-black text-uppercase mb-2">
                      Total Placed
                    </div>
                    <div className="h1 mb-0 font-weight-bold text-black">
                      {allCandidates.job}{" "}
                      <i className="fa fa-suitcase  text-black"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div> */}

        <div className="col-md-4 mb-4">
          {" "}
          <a href="Manage" style={{ textDecoration: "none" }}>
            {" "}
            <div
              className="card shadow h-100 py-2"
              style={{ backgroundColor: "rgba(51, 255, 153, 0.9)" }}
            >
              <div className="card-body">
                <div className="text-center">
                  {" "}
                  <div className="row mt-2">
                    <div className="col">
                      <div className="h6 text-xs font-weight-bold text-black text-uppercase">
                        Joined
                      </div>
                      <div className="h6 mb-0 font-weight-bold text-blqack">
                      {joinedCount > 0 ? joinedCount : "0"}
                      </div>
                    </div>
                    <div className="col">
                      <div className="h6 text-xs font-weight-bold text-black text-uppercase">
                        Terminated
                      </div>
                      <div className="h6 mb-0 font-weight-bold text-blqack">
                      {terminatedCount > 0 ? terminatedCount : "0"}
                      </div>
                    </div>
                    <div className="col">
                      <div className="h6 text-xs font-weight-bold text-black text-uppercase">
                        Working
                      </div>
                      <div className="h6 mb-0 font-weight-bold text-black">
                      {workingCount > 0 ? workingCount : "0"}
                      </div>
                    </div>
                    <div className="col">
                      <div className="h6 text-xs font-weight-bold text-black text-uppercase">
                        Rejected
                      </div>
                      <div className="h6 mb-0 font-weight-bold text-black">
                      {rejectedCount > 0 ? rejectedCount : "0"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        {/* Graph */}
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Leads Data Graph
              </h6>
            </div>
            <div className="card-body">
              <div className="chart-container">
                {/* <canvas ref={chartRef}></canvas> */}
                <ChartComponent data={chartData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtsTrack;
