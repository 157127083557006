import React, { useEffect, useState } from "react";
import edMantra from "../../services/edMantra";
import { toast } from "react-toastify";
import { FaUserCircle } from "react-icons/fa";
import EDModal from "../../Components/EDModal/EDModal";
import TextInput from "../../Components/TextInput/TextInput";
import { DragAndDrop } from "../../Components/dragAndDrop/DragAndDrop";
import { EditPermissions } from "../../Components/EditPermissions/EditPermissions";
import { BiSolidEdit } from "react-icons/bi";
import { useSelector } from "react-redux";

const arr3 = [
  {
    id: "item-5-1708316745046",
    permission: "USER_MANAGEMENT"
  },
  {
    id: "item-1-1708316745046",
    permission: "LEAD_MANAGEMENT"
  },
  {
    id: "item-6-1708316745046",
    permission: "SOCIAL_MEDIA_MANAGEMENt"
  },
  {
    id: "item-6-1708315046",
    permission: "MARKETING_MANAGEMENT"
  },
  {
    id: "item-6-1708046",
    permission: "USER_PERMISSION"
  },
  {
    id: "item-6-145046",
    permission: "TRANING"
  },
  {
    id: "item-6-170831",
    permission: "REPORTNIG"
  },
  {
    id: "item-6-65677",
    permission: "CONTACT_MANAGMENT"
  },
  {
    id: "item-6-65467",
    permission: "SALES_MANAGEMENT"
  },
  {
    id: "item-6-667",
    permission: "CUSTOM_SERVICES"
  },
  {
    id: "item-6-656",
    permission: "EMAIL_INTEGRATION"
  },
  {
    id: "item-6-0987678",
    permission: "DOC_MANAGEMENT"
  },

  {
    id: "item-6-5676",
    permission: "HR_MANAGEMENT"
  },
  {
    id: "item-6-6567",
    permission: "REPORTING_ATS"
  },
  {
    id: "item-6-9876",
    permission: "FINANCE_MANAGEMENT"
  },
  {
    id: "item-6-54677",
    permission: "TRANING_MANAGEMENT"
  },
  {
    id: "item-6-170557",
    permission: "STUDENT_MANAGEMENT"
  },




]

const EmployeProfile = () => {
  const [userData, setUserData] = useState([]);
  const [userView, setuserView] = useState({});
  const [modal, setModal] = useState(false);
  const [isEditable, setEditanble] = useState(true);
  const arr1 = userView?.["Roles.PlaybleModule"] !== undefined && JSON.parse(userView?.["Roles.PlaybleModule"])
  const arr2 = userView?.["Roles.NonPlayble_module"] !== undefined && JSON.parse(userView?.["Roles.NonPlayble_module"])
  const [state, setState] = useState([arr1 || [], arr2 || [], arr3 || []]);
  const { data } = useSelector((state) => state?.currentSession?.userInfo)


  const getEmployeeData = async () => {
    try {
      const result = await edMantra.getEmployee({
        page: "1",
        limit: "8",
      });
      if (result.data.status === "141") {
        setUserData(result?.data?.data);
      } else {
        toast(result?.data?.error);
      }
    } catch (error) {
      toast("something went wrong ,please check your connection");
    }
  };

  const handleActivate = async (id, status) => {
    try {
      const result = await edMantra.activateEmployee({
        empId: id,
        empStatus: status,
      });
      if (result?.data?.status == "142") {
        toast(result?.data?.message);
        getEmployeeData();
      } else {
        toast(result?.data?.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMoal = (val) => {
    setModal(val);
  };

  const handleUserView = (e, item) => {
    setuserView(item);
    handleMoal(true);
  };


  useEffect(() => {
    getEmployeeData();
  }, [0]);

  useEffect(() => {
    setState([arr1 || [], arr2 || [], arr3 || []])
  }, [userView])


  const updatedDataSet = {
    empId: userView?.empId,
    fname: userView?.fname,
    mname: userView?.mname,
    lname: userView?.lname,
    phone: userView?.phone,
    email: userView?.email,
    perAddress: userView?.perAddress,
    resAddress: userView?.resAddress,
    dob: userView?.dob,
    dol: userView?.dol,
    ctc: userView?.ctc,
  }

  const handleUpdate = async () => {
    try {
      const result = await edMantra.updateProfile({
        formValues: updatedDataSet,
        modules: {
          PlaybleModule: JSON.stringify(state[0]),
          NonPlayble_module: JSON.stringify(state[1]),
        }
      })
      if (result.data.status === "144") {
        toast(result.data.message)
      }
      else {
        toast(result?.data?.error)
      }
    } catch (error) {
      toast("something went wrong ,please check your connection")
    }
    setEditanble(true)
    setModal(false)
    getEmployeeData()
  }

  const handleEditForm = (e) => {
    const { name, value } = e.target;
    setuserView((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  return (
    <div className="container mt-5">
      <div
        className="card "
        style={{ borderRadius: "15px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
      >
        <div className="card-header" style={{ borderRadius: "15px 15px 0 0" }}>
          <b style={{ color: "black" }}>Employee Profile</b>
        </div>
        <div className="card-body">
          <table class="table">
            <thead>
              <tr>
                <th scope="col my-3">Sno</th>
                <th scope="col my-3">Emp Id</th>
                <th scope="col my-3">First Name</th>
                <th scope="col my-3">Last Name</th>
                <th scope="col my-3">Email</th>
                {/* <th scope="col my-3">Phone</th> */}
                {/* <th scope="col my-3">Dob</th> */}
                {/* <th scope="col my-3">Doj</th> */}
                <th scope="col my-3">Role</th>
                <th scope="col my-3">Branch</th>
                <th scope="col my-3">Status</th>
                <th scope="col my-3">View</th>
              </tr>
            </thead>
            <tbody>
              {userData &&
                userData?.length > 0 &&
                userData.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.empId}</td>
                      <td>{item?.fname}</td>
                      <td>{item?.lname}</td>
                      <td>{item?.email}</td>

                      <td>{item?.["Roles.roleName"]}</td>
                      <td>{item?.branch}</td>
                      <td>
                        <button
                          type="button"
                          className={`btn ${item?.empStatus === "inactive" ? "btn-danger" : "btn-success"}`}
                          onClick={() =>
                            item?.empStatus === "inactive"
                              ? handleActivate(item.empId, "active")
                              : handleActivate(item.empId, "inactive")
                          }
                        >
                          {item?.empStatus}
                        </button>

                      </td>
                      <td>
                        <FaUserCircle
                          size={30}
                          onClick={(e) => handleUserView(e, item)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      {/* <DragAndDrop/> */}

      <EDModal isOpen={modal} handleClose={(val) => handleMoal(val)} size="xl">
        <div className="container">
          <BiSolidEdit size={25} onClick={() => setEditanble(!isEditable)} />
          <p className="p-0 m-0 fs-5 text-center fw-bold">EMPLOYEE INFO</p>
          <div className="row p-0 m-0">
            <div className="col-md-12 mx-auto">
              <div class="row row-cols-2 row-cols-md-3">
                <div class="col my-3">
                  <TextInput
                    title="First Name"
                    name="fname"
                    placeholder="Enter first name"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.fname}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="Middle Name"
                    name="mname"
                    placeholder="Enter middle name"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.mname}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="Last Name"
                    name="lname"
                    placeholder="Enter last name"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.lname}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="Phone No."
                    name="phone"
                    placeholder="Enter phone number"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.phone}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="Email"
                    name="email"
                    placeholder="Enter email"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.email}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="Desingnation"
                    name="role"
                    placeholder="Select role"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.["Roles.roleName"]}
                    readOnly={true}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="CTC"
                    name="ctc"
                    placeholder="Enter ctc"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.ctc}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="DOB"
                    name="dob"
                    placeholder="Enter DOB"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.dob}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="Joining Date"
                    name="doj"
                    placeholder="Enter joining date"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.doj}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="Leaving Date"
                    name="dol"
                    placeholder="Enter leaving date"
                    onChange={(e) => handleEditForm(e)}
                    value={"NA"}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="Permanent Address"
                    name="perAddress"
                    placeholder="Enter permanent address"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.perAddress}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3">
                  <TextInput
                    title="Residence Address"
                    name="resAddress"
                    placeholder="Enter residence address"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.resAddress}
                    readOnly={isEditable}
                    custStyle={!isEditable && "border-success"}
                  />
                </div>
                <div class="col my-3 ">
                  <TextInput
                    title="Status"
                    name="empStatus"
                    placeholder="Enter first name"
                    onChange={(e) => handleEditForm(e)}
                    value={userView?.empStatus}
                    readOnly={true}
                    custStyle={!isEditable && "border-danger"}
                  />
                </div>
              </div>
            </div>
          </div>

          <p className="p-0 m-0 fs-5 text-center fw-bold">PERMISSIONS</p>

          <EditPermissions
            isEditable={data?.role?.roleName === "AdminDm" ? !isEditable : false}
            state={state}
            setState={setState}
          />
        </div>

        <div className="text-end p-4">
          {!isEditable && <button type="button" class="btn btn-outline-success rounded-pill " onClick={handleUpdate} >Update</button>}
        </div>

      </EDModal>
    </div>
  );
};

export default EmployeProfile;

