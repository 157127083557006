import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createSession } from "../../Redux/reducers/SessionSlice";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import logo1 from '../../assets/images/Logo DM.png'
import logo2 from '../../assets/images/Logo EM.png'
import "./Header.css";
import { FaPowerOff, FaEye, FaEyeSlash } from "react-icons/fa";
import edMantra from "../../services/edMantra";

const Header = () => {
  const navigate = useNavigate();
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(null);


  // State for Password Fields
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);


  // State for Errors
  const [errors, setErrors] = useState({});

  // Handle Input Change
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleLogout = () => {
    navigate("/");
    Cookies.remove("jwt");
    dispatch(createSession({}));
    window.location.reload();
  };

  // Handle Show/Hide Password
  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // **Validation Function**
  const validateForm = () => {
    let newErrors = {};

    if (!formData.oldPassword) newErrors.oldPassword = "Old password is required.";
    if (!formData.newPassword) newErrors.newPassword = "New password is required.";
    else if (formData.newPassword.length < 6) newErrors.newPassword = "Password must be at least 6 characters.";

    if (!formData.confirmNewPassword) newErrors.confirmNewPassword = "Please confirm your new password.";
    else if (formData.newPassword !== formData.confirmNewPassword) newErrors.confirmNewPassword = "Passwords do not match.";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // **Handle Save Changes**
  const handleSaveChanges = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const requestData = {
        empId: data?.empId,  // Include empId in the request
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword
      };
      try {
        const result = await edMantra.changePassword(requestData);
        if (result.status === 200){
          toast("Password changed successfully");
        }
        else if(result.status === 404){
          toast("User not found");
        }else{
          toast("Something went worng");
        }

        setFormData({
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        })
        modal.hide();
      }
       catch (error) {
       if(error.response.status === 400){
        toast("Incorrect old password");
       }
       else{
        toast("Something went worng");
       }
      }
    }
  };

  //change password
  const changePassword = () => {
    const modalInstance = new window.bootstrap.Modal(document.getElementById("changePasswordModal"));
    modalInstance.show();
    setModal(modalInstance);
  }





  // Move the greeting function outside of handleLogout
  const greeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return (<>
        Good Morning ,<br />
        Hope you have a productive day ahead!
      </>)
    } else if (currentHour < 18) {
      return (<>
        Good After Noon,<br />
        Hope you are doing great!
      </>)
    } else {
      return (<>
        Good Evening,<br />
        A relaxing and pleasant evening!!
      </>)
    }
  };

  return (
    <>
      <nav className="navbar gradient border-bottom border-body mb-5 sticky-lg-top" data-bs-theme="dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="https://mantraserp.com/">
            <div className="centered-container">
              <div className="logo-container">
                <img src={logo2} className="img-fluid mx-2 logo logo1" alt="logo" width='60px' />
                <img src={logo1} className="img-fluid mx-2 logo logo2" alt="logo" width='60px' />
              </div>
            </div>
          </a>
          <form className="d-flex" role="search">
            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-warning mr-2 btn-sm" type="submit">Search</button>
          </form>
          {/* 
          <p className="text-light">
            Hi <span className="fw-medium text-warning">{data?.fname}</span>
            <img src="img/undraw_profile.svg" style={{ width: "20px" }} className="img-profile rounded-circle" alt="Profile" /><br />
            {greeting()}
            <button className="btn btn-outline-warning ml-2" onClick={handleLogout}>
              <FaPowerOff /> Logout
            </button>
          </p> */}
          <div className="dropdown">
            <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              Hi <span className="fw-medium text-warning">{data?.fname}</span>
              <img src="img/undraw_profile.svg" style={{ width: "20px" }} className="img-profile rounded-circle " alt="Profile" />
            </button>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <li className="dropdown-item-text">
                {greeting()}
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li className="dropdown-item-text">{data?.empId}</li>
              <li><hr className="dropdown-divider" /></li>
              <li>
                <button className="dropdown-item" onClick={changePassword}>
                  Change Password
                </button>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li>
                <button className="dropdown-item" onClick={handleLogout}>
                  <FaPowerOff /> Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Bootstrap Modal for Change Password */}
      <div className="modal fade" id="changePasswordModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Change Password</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => modal.hide()} aria-label="Close"></button>
            </div>
            <div className="modal-body">
            {/* {errors.apiError && <div className="alert alert-danger">{errors.apiError}</div>}  */}
              <form onSubmit={handleSaveChanges}>
                {/* Old Password */}
                <div className="mb-3 position-relative">
                  <label className="form-label">Old Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword.oldPassword ? "text" : "password"}
                      className={`form-control ${errors.oldPassword ? "is-invalid" : ""}`}
                      name="oldPassword"
                      value={formData.oldPassword}
                      onChange={handleInputChange}
                      placeholder="Enter old password"
                    />
                    <span className="input-group-text" onClick={() => togglePasswordVisibility("oldPassword")} style={{ cursor: "pointer" }}>
                      {showPassword.oldPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                    {errors.oldPassword && <div className="invalid-feedback">{errors.oldPassword}</div>}
                  </div>
                </div>

                {/* New Password */}
                <div className="mb-3 position-relative">
                  <label className="form-label">New Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword.newPassword ? "text" : "password"}
                      className={`form-control ${errors.newPassword ? "is-invalid" : ""}`}
                      name="newPassword"
                      value={formData.newPassword}
                      onChange={handleInputChange}
                      placeholder="Enter new password"
                    />
                    <span className="input-group-text" onClick={() => togglePasswordVisibility("newPassword")} style={{ cursor: "pointer" }}>
                      {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                    {errors.newPassword && <div className="invalid-feedback">{errors.newPassword}</div>}
                  </div>
                </div>

                {/* Confirm New Password */}
                <div className="mb-3 position-relative">
                  <label className="form-label">Confirm New Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword.confirmNewPassword ? "text" : "password"}
                      className={`form-control ${errors.confirmNewPassword ? "is-invalid" : ""}`}
                      name="confirmNewPassword"
                      value={formData.confirmNewPassword}
                      onChange={handleInputChange}
                      placeholder="Confirm new password"
                    />
                    <span className="input-group-text" onClick={() => togglePasswordVisibility("confirmNewPassword")} style={{ cursor: "pointer" }}>
                      {showPassword.confirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                    {errors.confirmNewPassword && <div className="invalid-feedback">{errors.confirmNewPassword}</div>}
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => modal.hide()}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
