import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../../Pages/Dashboard/Dashboard";
import Profile from "../../Pages/Profile";
import Settings from "../../Pages/Settings";
import Logout from "../../Pages/Logout";
import Manage from "../../Pages/LeadManagementPages/Manage";
import Track from "../../Pages/LeadManagementPages/Track";
import AtsTrack from "../../Pages/AplicantTrackingSystem/AtsTrack";
import SalesPipeline from "../../Pages/LeadManagementPages/SalesPipeline";
import Registration from "../../Pages/LeadManagementPages/Registration";
import Admission from "../../Pages/LeadManagementPages/Admission";
import SalesQuotes from "../../Pages/SalesAutomationPages/SalesQuotes";
import MarketingAutomation from "../../Pages/MarketingAutomation";
import Complaints from "../../Pages/CustomerServiceAndSupportPages/Complaints";
import EmailIntegration from "../../Pages/EmailIntegration";
import CustomerDocuments from "../../Pages/DocumnetManagementPages/CustomerDocuments";
import Contracts from "../../Pages/DocumnetManagementPages/Contracts";
import Files from "../../Pages/DocumnetManagementPages/Files";
import SocialMediaIntegration from "../../Pages/SocialMediaIntegration";
import Role from "../../Pages/UserPermissionsAndSecurityPages/Role";
import StudentAccount from "../../Pages/StudentManagementPages/StudentAccount";
import CoursesStructure from "../../Pages/StudentManagementPages/CoursesStructure";
import AttendanceTracking from "../../Pages/StudentManagementPages/AttendanceTracking";
import GradesAndProgressReport from "../../Pages/StudentManagementPages/GradesAndProgressReport";
import TimeTableAndSchedule from "../../Pages/StudentManagementPages/TimeTableAndSchedule";
import Eventcalender from "../../Pages/StudentManagementPages/Eventcalender";
import FeeManagement from "../../Pages/StudentManagementPages/FeeManagement";
import StudentAccessControl from "../../Pages/StudentManagementPages/StudentAccessControl";
import EmployeProfile from "../../Pages/HrManagementPages/EmployeProfile";
import EmployeAttendance from "../../Pages/HrManagementPages/EmployeAttendance";
import TimeTracking from "../../Pages/HrManagementPages/TimeTracking";
import PayrollManagement from "../../Pages/HrManagementPages/PayrollManagement";
import DocumentManagement from "../../Pages/HrManagementPages/DocumentManagement";
import GeneralParsing from "../../Pages/FinanceManagementPages/GeneralParsing";
import DoubleEnteryAccounting from "../../Pages/FinanceManagementPages/DoubleEnteryAccounting";
import Invoicing from "../../Pages/FinanceManagementPages/Invoicing";
import Reporting from "../../Pages/Reporting/Reporting";
import ResumeParsing from "../../Pages/AplicantTrackingSystem/ResumeParsing";
import JobParsing from "../../Pages/AplicantTrackingSystem/JobParsing";
import CandidateManagement from "../../Pages/AplicantTrackingSystem/CandidateManagement";
import CollabrationTool from "../../Pages/AplicantTrackingSystem/CollabrationTool";
import InterviewScheduling from "../../Pages/AplicantTrackingSystem/InterviewScheduling";
import AnalyticsAndReporting from "../../Pages/AplicantTrackingSystem/AnalyticsAndReporting";
import Placements from "../../Pages/AplicantTrackingSystem/Placements";
import Registrations from "../../Pages/HrManagementPages/Registrations";
import LeaveManagemnet from "../../Pages/HrManagementPages/LeaveManagemnet";
import Archive from "../../Pages/LeadManagementPages/Archive"
import { useSelector } from "react-redux";
import CandidateOnboarding from "../../Pages/AplicantTrackingSystem/CandidateOnboarding";
import VacancyManagement from "../../Pages/AplicantTrackingSystem/VacancyManagement";
import ATSReports from "../../Pages/AplicantTrackingSystem/ATSReports";
import AtsDashboard from "../../Pages/Dashboard/AtsDashboard";

const routesWithPermisions = [

  
  {
    permission: "LEAD_MANAGEMENT",
    componentSet: (canEdit) => {
      return (
        <>
          {" "}
          <Route path="/track" element={<Track canEdit={canEdit} />}></Route>
          <Route path="/manage" element={<Manage canEdit={canEdit} />}></Route>
          <Route
            path="/salespipeline"
            element={<SalesPipeline canEdit={canEdit} />}
          ></Route>
          <Route
            path="/registration"
            element={<Registration canEdit={canEdit} />}
          ></Route>
          <Route
            path="/admission"
            element={<Admission canEdit={canEdit} />}
          ></Route>


          <Route
            path="/archive"
            element={<Archive canEdit={canEdit} />}
          ></Route>
        </>
      );
    },
  },

  {
    permission: "SALES_MANAGEMENT",
    componentSet: (canEdit) => {
      return (
        <>
          <Route
            path="/salesquotes"
            element={<SalesQuotes canEdit={canEdit} />}
          ></Route>
        </>
      );
    },
  },
  {
    permission: "MARKETING_MANAGEMENT",
    componentSet: (canEdit) => {
      return (
        <>
          <Route
            path="/marketingautomation"
            element={<MarketingAutomation canEdit={canEdit} />}
          ></Route>
        </>
      );
    },
  },
  {
    permission: "CUSTOM_SERVICES",
    componentSet: (canEdit) => {
      return (
        <>
          <Route
            path="/complaints"
            element={<Complaints canEdit={canEdit} />}
          ></Route>
        </>
      );
    },
  },
  {
    permission: "EMAIL_INTEGRATION",
    componentSet: (canEdit) => {
      return (
        <>
          <Route
            path="/emailintegration"
            element={<EmailIntegration canEdit={canEdit} />}
          ></Route>
        </>
      );
    },
  },
  {
    permission: "DOC_MANAGEMENT",
    componentSet: (canEdit) => {
      return (
        <>
          <Route
            path="/customerdocuments"
            element={<CustomerDocuments canEdit={canEdit} />}
          ></Route>
          <Route
            path="/contracts"
            element={<Contracts canEdit={canEdit} />}
          ></Route>
          <Route path="/files" element={<Files canEdit={canEdit} />}></Route>
        </>
      );
    },
  },
  {
    permission: "SOCIAL_MEDIA_MANAGEMENt",
    componentSet: (canEdit) => {
      return (
        <>
          <Route
            path="/socialmediaintegration"
            element={<SocialMediaIntegration canEdit={canEdit} />}
          ></Route>
        </>
      );
    },
  },
  {
    permission: "USER_PERMISSION",
    componentSet: (canEdit) => {
      <>
        <Route path="/role" element={<Role canEdit={canEdit} />}></Route>
      </>;
    },
  },
  {
    permission: "TRANING",
    componentSet: (canEdit) => {
      return (
        <>
          <Route
            path="/studentaccount"
            element={<StudentAccount canEdit={canEdit} />}
          ></Route>
          <Route
            path="/coursesstructure"
            element={<CoursesStructure canEdit={canEdit} />}
          ></Route>
          <Route
            path="/attendancetracking"
            element={<AttendanceTracking canEdit={canEdit} />}
          ></Route>
          <Route
            path="/gradesandprogressreport"
            element={<GradesAndProgressReport canEdit={canEdit} />}
          ></Route>
          <Route
            path="/timetableandschedule"
            element={<TimeTableAndSchedule canEdit={canEdit} />}
          ></Route>
          <Route
            path="/eventcalender"
            element={<Eventcalender canEdit={canEdit} />}
          ></Route>
          <Route
            path="/feemanagement"
            element={<FeeManagement canEdit={canEdit} />}
          ></Route>
          <Route
            path="/studentaccesscontrol"
            element={<StudentAccessControl canEdit={canEdit} />}
          ></Route>
        </>
      );
    },
  },
  {
    permission: "HR_MANAGEMENT",
    componentSet: (canEdit) => {
      return (
        <>
          <Route
            path="/registrations"
            element={<Registrations canEdit={canEdit} />}
          ></Route>
          <Route
            path="/employeprofile"
            element={<EmployeProfile canEdit={canEdit} />}
          ></Route>
          <Route
            path="/employeattendance"
            element={<EmployeAttendance canEdit={canEdit} />}
          ></Route>
          <Route
            path="/leavemanagemnet"
            element={<LeaveManagemnet canEdit={canEdit} />}
          ></Route>
          <Route
            path="/timetracking"
            element={<TimeTracking canEdit={canEdit} />}
          ></Route>
          <Route
            path="/payrollmanagement"
            element={<PayrollManagement canEdit={canEdit} />}
          ></Route>
          <Route
            path="/documentmanagement"
            element={<DocumentManagement canEdit={canEdit} />}
          ></Route>
        </>
      );
    },
  },

  {
    permission: "FINANCE_MANAGEMENT",
    componentSet: (canEdit) => {
      return (
        <>
          <Route
            path="/generalparsing"
            element={<GeneralParsing canEdit={canEdit} />}
          ></Route>
          <Route
            path="/doubleenteryaccounting"
            element={<DoubleEnteryAccounting canEdit={canEdit} />}
          ></Route>
          <Route
            path="/invoicing"
            element={<Invoicing canEdit={canEdit} />}
          ></Route>
        </>
      );
    },
  },
  {
    permission: "TRANING_MANAGEMENT",
    componentSet: (canEdit) => {
      return (
        <>
          <Route path="/role" element={<Role canEdit={canEdit} />}></Route>
        </>
      );
    },
  },

  {
    permission: "REPORTNIG",
    componentSet: (canEdit) => {
      return (
        <>
          <Route
            path="/reporting"
            element={<Reporting canEdit={canEdit} />}
          ></Route>
        </>
      );
    },
  },
];

const AppRoutes = () => {
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const userPermisionList2 = data?.role?.Nonplayble_module;
  const userPermisionList = data?.role?.PlaybleModule;

  console.log(userPermisionList, userPermisionList2);
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="/atsdashboard" element={<AtsDashboard />}></Route>
      <Route path="/profile" element={<Profile />}></Route>
      <Route path="/settings" element={<Settings />}></Route>
      <Route path="/logout" element={<Logout />}></Route>

      {routesWithPermisions?.map((obj) => {
        return (
          userPermisionList !== undefined &&
          JSON.parse(userPermisionList)?.map((userPermission) => {
            if (obj?.permission === userPermission?.permission) {
              return obj?.componentSet(true);
            } else {
              userPermisionList2 !== undefined &&
                JSON.parse(userPermisionList2)?.map((userPermission) => {
                  if (obj?.permission === userPermission?.permission)
                    return obj?.componentSet(false);
                });
            }
          })
        );
      })}
      <Route path="/resumeparsing" element={<ResumeParsing />}></Route>
      <Route path="/jobparsing" element={<JobParsing />}></Route>
      <Route
        path="/candidatemanagement"
        element={<CandidateManagement />}
      ></Route>
       <Route path="/atstrack" element={<AtsTrack/>}></Route>
      <Route path="/collabrationtool" element={<CollabrationTool />}></Route>
      <Route path="/vacancymanagement" element={<VacancyManagement />}></Route>
      <Route path="/interviewscheduling" element={<InterviewScheduling />}></Route>
      <Route path="/atsreports" element={<ATSReports />}></Route>
      <Route
        path="/candidateonboarding"
        element={<CandidateOnboarding />}
      ></Route>
      <Route
        path="/analyticsandreporting"
        element={<AnalyticsAndReporting />}
      ></Route>
      <Route path="/placements" element={<Placements />}></Route>
    </Routes>
  );
};

export default AppRoutes;
