import React, { useState } from "react";
import { toast } from "react-toastify";
import edMantra from "../../services/edMantra";
import "./style/Login.css";
import { OtpScreen } from "./OtpScreen";
import logo1 from "../../assets/images/Logo EM.png"
import logo2 from "../../assets/images/Logo DM.png"
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";


const Login = () => {
  const [otp, setOtp] = useState("");
  const [isActive, setIsActive] = useState("login");
  const [showPass, setShowPass] = useState(false)
  const [formData, setFormData] = useState({
    empId: "",
    password: "", 
  });
 

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (formData, event) => {
    try {
      const result = await edMantra.login(formData);
      if (result.data.status === "129") {
        toast("Loged in successfully");
        setFormData({ empId: "", password: "" });
        window.location.reload();
      } else if (result.data.status === "-127") {
        setIsActive("otp");
      } else {
        toast(result?.data?.error);
      }
    } catch (error) {
      toast("something went wrong ,please check you connection");
    }
  };

  const handleOtpSubmit = async (e) => {
    try {
      const result = await edMantra.verifyOtp({
        empId: formData?.empId,
        otp: otp,
      });
      if (result.data.status === "133") {
        toast("OTP verified successfully");
        setIsActive("login");
      } else {
        toast(result?.data?.error);
      }
    } catch (error) {
      toast("something went wrong ,please check you connection");
    }
  };

  const login = () => {
    return (
      <>
     <div className="login-page">
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="row w-100">
          <div className="col-lg-6 mx-auto">
            
            <div className="card border border-light-subtle rounded-3 shadow-sm  zoom-in">
                  <div className="card-body p-3 p-md-4 p-xl-5 ">
                    <div className="centered-container">
                    <div className="logo-container">
                      <img src={logo2} className="img-fluid mx-2 logo logo1" alt="logo" width='60px' />
                      <img src={logo1} className="img-fluid mx-2 logo logo2" alt="logo" width='60px' />
                    </div>
                    </div>
                    <h2 className="fs-3 fw-normal text-center text-secondary mb-2 mt-2">
                      {/* Welcome to Mantras ERP */}
                    </h2>
                    <div className="row gy-2 overflow-hidden">
                      <div className="col-12">
                        <div className="mb-3">
                          <label for="email" className="form-label">
                            Username
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="empId"
                            id="email"
                            placeholder="&#xF007; Type your User Name or Employee ID" style={{ fontFamily: "Arial, FontAwesome" }} 
                            value={formData.empId}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <label for="password" className="form-label">
                          Password
                        </label>
                        <div class="input-group mb-3">
                          <input
                            type={showPass ? 'text' : 'password'}
                            className="form-control"
                            name="password"
                            id="password"
                            placeholder="&#xf023; Type your Password" style={{ fontFamily: "Arial, FontAwesome" }} 
                            value={formData.password}
                            onChange={handleInputChange}
                            aria-describedby="button-addon2"
                            required
                          />
                          <button class="btn btn-outline-secondary" 
                          type="button" id="button-addon2" 
                          onClick={() => setShowPass(!showPass)}>{showPass ? <IoEye size="20" /> : < IoMdEyeOff size="20" />}</button>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex gap-2 justify-content-between">
                          <div className="form-check">
                          </div>
                          <a
                            href="#!"
                            className="link-primary text-decoration-none"
                          >
                            Forgot password?
                          </a>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-grid ">
                          <button
                            className="btn btn-warning btn-block fw-bold mb-2"
                            type="submit"
                            onClick={(event) => handleSubmit(formData, event)}
                          >
                            Log in
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
      </>
    );
  };

  return (
    <>
      {isActive === "login" ? (
        login()
      ) : (
        <OtpScreen
          handleOtp={(e) => handleOtpSubmit(e)}
          otp={otp}
          setOtp={setOtp}
          resendOtp={(event) => handleSubmit(formData, event)}
        />
      )}
    </>
  );
};

export default Login;
