import React, { useState, useEffect, useRef, useMemo } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from "react-toastify";

import edMantra from "../../services/edMantra";
import { useSelector } from "react-redux";
import EDLeadForm from "../../Components/EDLeadForm/EDLeadForm";
import EDModal from "../../Components/EDModal/EDModal";
import { SiMicrosoftexcel } from "react-icons/si";
import leadsamplefile from "../../assets/files/leadfile.xlsx";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineDelete, MdEdit, MdEmail } from "react-icons/md";
import EDAddLead from "../../Components/EDLeadForm/EDAddLead";
import { FaFileDownload, FaFileExport, FaAddressCard, FaCloudUploadAlt, FaFilter,FaArchive } from "react-icons/fa";
import './Manage.css'
import './Globalstyle.css'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search, CSVExport, pagination } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import loadinggif from '../../assets/images/loading.gif'

const Manage = (props) => {
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const fileInputRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [allRemarks, setRemarks] = useState([]);
  const [leadObject, setLeadObject] = useState(null);
  const [allEmployees, setAllEmployees] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [file, setFile] = useState({});
  const [toggle, setToggle] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [audioFile, setAdudioFile] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  // State to store the IDs of selected leads
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);





  const handleEditButtonClick = (lead) => {
    setLeadObject(lead);
    setRemarks(lead.Remarks);
    setShowModal(true);
  };

  const fetchData = async () => {
    setIsLoading(true); // Set loading to true
    try {
      const response = await edMantra.getAllLeads();
      setLeadData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set loading to false after the fetch
    }
  };

  const getAllEmployees = async () => {
    try {
      const allEmployees = await edMantra.getAllEmployees();
      setAllEmployees(allEmployees.data.data);
    } catch (err) {
      console.error(err, "Error fetching data");
    }
  };
  const handleFileChange = (e, index) => {
    setFile(e.target.files[0]);
    setToggle(!toggle);
  };
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const result = await edMantra.uploadLead(formData);
      if (result.status === 200) {
        setToggle(false);
        setRefresh(!refresh);
        toast(result?.data?.message);
        setFile({});
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleSave = async (val) => {
    try {
      const formData = new FormData();
      formData.append("audio", audioFile);
      formData.append("lead_id", val.LeadId);
      formData.append("emp_id", data?.empId);
      const response = await edMantra.updateLeadByLeadId(val.LeadId, val);
      if (response.status === 200 && audioFile) {
        const result = await edMantra.uploadLeadAudio(formData);
      }
      setShowModal(false);
      toast(response.data.message);
      fetchData();
      setEditableRowIndex(null);
    } catch (error) {
      console.error(
        error.message || "An error occurred while updating the lead."
      );
    }
  };

  const handleDelete = async (lead) => {
    try {
      const result = await edMantra.deleteLeadByLeadId(lead.LeadId);
      if (result?.data.errorCode === 0) {
        setRefresh(!refresh);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  // Function to handle assigning telecaller
  const handleAssigneeUpdate = async (empId) => {
    try {
      // Check if any rows are selected
      if (selectedRows.length === 0) {
        // Notify the user to select rows
        toast.error("Please select at least one row.");
        return;
      }

      // Update telecaller for each selected row
      const updatedRows = await Promise.all(selectedRows.map(async (row) => {
        try {
          const response = await edMantra.updateLeadByLeadId(row.LeadId, {
            ...row,
            telecaller: empId,
            remark: '', // Assuming remark needs to be updated as well
          });
          // Log success message
          console.log(response.data.message);
          // Return the updated row
          return response.data.updatedLead;
        } catch (err) {
          // Log error message
          console.error(err.message || "Error updating telecaller");
          // Return null to indicate failure
          return null;
        }
      }));

      // Check if all updates were successful
      if (updatedRows.every(row => row !== null)) {
        // Notify user about successful updates
        toast.success("Lead Assignee updated successfully.");
        // Refetch data or update state as needed
        fetchData();
      } else {
        // Notify user about partial failure or handle as needed
        toast.error("Some updates failed. Please try again.");
      }
    } catch (err) {
      // Log error message or handle as needed
      console.error(err.message || "Something went wrong");
    }
  };






  useEffect(() => {
    getAllEmployees();
  }, []);

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const openwhatsApp = async (phone) => {
    try {
      if (!phone.startsWith("+91")) {
        phone = "+91" + phone;
      }
      const whatsappUrl = `https://wa.me/${phone}`;
      window.open(whatsappUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const openEamil = (email) => {
    const recipient = email;
    const subject = "Subject of the email";
    const body = "Content of the email";
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const handleAddlead = (params) => {
    setAddModal(true);
  };

  const formatDateforleadinout = (dateString) => { //for lead craetedAt and updatedAt
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Include this option for 12-hour format
    };
    return date.toLocaleDateString('en-GB', options);
  };


  const formatDatefornextfollow = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Include this option for 12-hour format
    };
    return date.toLocaleDateString('en-GB', options);
  };



  //----------------bulkdelete----------------------------


  // Assuming you have a function to handle row deselection in your table
  const handleRowDeselect = (row) => {
    // Remove the deselected row's ID from selectedLeadIds state
    setSelectedLeadIds(selectedLeadIds.filter(id => id !== row.LeadId));
  };


  // const handleBulkDelete = async () => {
  //   try {
  //     const result = await edMantra.deleteBulkLead({leads:selectedRows})
  //     if (result.data.errorCode===0){
  //       toast.success(result.data.message);
  //     }
  //   } catch (error) {
  //     console.error(error);

  //   }
  // };

  const handleBulkDead = async () => {
    try {
      const result = await edMantra.deadBulkLead({ leads: selectedRows });

      if (result.data.errorCode === 0) {
        toast.success(result.data.message);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        // Handle known errors from server response
        toast.error(error.response.data.message || "Internal server error");
      } else {
        // Handle unknown errors
        toast.error("Internal server error");
      }
      console.error("Error in handleBulkDead:", error);
    }
  };



  //---------------------------------


  //---------------source filter option--------------------


  const leadSources = [
    { value: '', label: 'Select a source' }, // Default option
    { value: 'Website', label: 'Website' },
    { value: 'Facebook Ad', label: 'Facebook Ad' },
    { value: 'Instagram Ad', label: 'Instagram Ad' },
    { value: 'Google Ad', label: 'Google Ad' },
    { value: 'Just Dial', label: 'Just Dial' },
    { value: 'Walk-In', label: 'Walk-In' },
    { value: 'Incoming Call', label: 'Incoming Call' },
    { value: 'Reference', label: 'Reference' },
    { value: 'Alumni', label: 'Alumni' },
    { value: 'Field Data', label: 'Field Data' },
    { value: 'Seminar', label: 'Seminar' },
    { value: 'Webinar', label: 'Webinar' },
    { value: 'Schools', label: 'Schools' },
    { value: 'Coaching\'s', label: 'Coaching\'s' },
    { value: 'Event', label: 'Event' },
    { value: 'Activities', label: 'Activities' },
    { value: 'Admission Centre', label: 'Admission Centre' },
    { value: 'Franchise', label: 'Franchise' },
    { value: 'Self-Generated', label: 'Self-Generated' },
    { value: 'Employment Mantras', label: 'Employment Mantras' },
    { value: 'Purchased Data', label: 'Purchased Data' },
    { value: 'WhatsApp', label: 'WhatsApp' },
    { value: 'WhatsApp Status', label: 'WhatsApp Status' },
    { value: 'LinkedIn', label: 'LinkedIn' },
    { value: 'GMB', label: 'GMB' },
    { value: 'WhatsApp Group', label: 'WhatsApp Group' },
    { value: 'Other', label: 'Other' },
    {Value: 'Google', label:'Google'}
  ];
  const leadSourceFilter = selectFilter({
    options: leadSources
  });
  //-----------------------------------------------------------
  const filterByMultipleEducation = (filterValue, data) => {
    if (!filterValue) return data; // If no filter, return all data

    const selectedEducation = filterValue
      .split(",") // Split input by commas
      .map((pincode) => pincode.trim().toLowerCase()); // Trim spaces & convert to lowercase for case-insensitive matching

    return data.filter(
      (row) => selectedEducation.includes(row.pincode.toLowerCase()) // Check if row sector matches any selected industry
    );
  };
 
  
  const filterByMultipleCity = (filterValue, data) => {
    if (!filterValue) return data; // If no filter, return all data

    const selectedCity = filterValue
      .split(",") // Split input by commas
      .map((city) => city.trim().toLowerCase()); // Trim spaces & convert to lowercase for case-insensitive matching

    return data.filter(
      (row) => selectedCity.includes(row.city.toLowerCase()) // Check if row sector matches any selected industry
    );
  };

  const filterByMultipleQuery = (filterValue, data) => {
    if (!filterValue) return data; // If no filter, return all data

    const selectedQuery = filterValue
      .split(",") // Split input by commas
      .map((query) => query.trim().toLowerCase()); // Trim spaces & convert to lowercase for case-insensitive matching

    return data.filter(
      (row) => selectedQuery.includes(row.query.toLowerCase()) // Check if row sector matches any selected industry
    );
  };

  const filterByMultipleStatus = (filterValue, data) => {
    if (!filterValue) return data; // If no filter, return all data

    const selectedStatus = filterValue
      .split(",") // Split input by commas
      .map((status) => status.trim().toLowerCase()); // Trim spaces & convert to lowercase for case-insensitive matching

    return data.filter(
      (row) => selectedStatus.includes(row.status.toLowerCase()) // Check if row sector matches any selected industry
    );
  };

  const filterByMultipleLeadSource = (filterValue, data) => {
    if (!filterValue) return data; // If no filter, return all data
  
    const selectedLeadSource = filterValue
      .split(",") // Split input by commas
      .map((leadSource) => leadSource.trim().toLowerCase()); // Trim spaces & convert to lowercase for case-insensitive matching
  
    return data.filter((row) => {
      const leadSource = row.source; // Access 'source' instead of 'leadSources'
      // Ensure leadSource is a non-empty string before processing
      return (
        typeof leadSource === "string" &&
        selectedLeadSource.includes(leadSource.toLowerCase())
      );
    });
  };
  
  
  // -----------data table--------------------------------------------
  const { ExportCSVButton } = CSVExport;
  const columns = [{
    dataField: 'SrNo',
    text: 'Sr. No',
    sort: 'true',
    filter: textFilter()
  }, {
    dataField: 'fullName',
    text: 'Full Name',
    sort: 'true',
    filter: textFilter()
  }, {
    dataField: 'mobile',
    text: 'Contact No.',
    sort: 'true',
    filter: textFilter()
  },

  {
    dataField: 'city',
    text: 'City',
    sort: 'true',
    filter: textFilter({
      placeholder: "Enter City (comma-separated)", // Guide the user
      onFilter: (filterValue, data) =>
        filterByMultipleCity(filterValue, data),
    }),
  },
  {
    dataField: 'pincode',
    text: 'Education',
    sort: 'true',
    filter: textFilter({
      placeholder: "Enter Education (comma-separated)", // Guide the user
      onFilter: (filterValue, data) =>
        filterByMultipleEducation(filterValue, data),
    }),
    headerStyle: { minWidth: "120px" }, // Set minimum width
  },
  {
    dataField: 'query',
    text: 'Query',
    sort: 'true',
    filter: textFilter({
      placeholder: "Enter Query (comma-separated)", // Guide the user
      onFilter: (filterValue, data) =>
        filterByMultipleQuery(filterValue, data),
    }),
    headerStyle: { minWidth: "120px" }, // Set minimum width

  },
  {
    dataField: 'status',
    text: 'Status',
    sort: 'true',
    filter: textFilter({
      placeholder: "Enter Status (comma-separated)", // Guide the user
      onFilter: (filterValue, data) =>
        filterByMultipleStatus(filterValue, data),
    }),
    headerStyle: { minWidth: "120px" }, // Set minimum width

  }, {

    dataField: 'nextfollow',
    text: 'Next Follow Up Date',
    sort: 'true',
    filter: dateFilter(),
    formatter: (cell, row) => formatDatefornextfollow(cell)
    

  }, {
    dataField: 'createdAt',
    text: 'Create Time',
    sort: 'true',
    filter: dateFilter(),
    formatter: (cell, row) => formatDateforleadinout(cell)
  }, {
    dataField: 'updatedAt',
    text: 'Update Time',
    sort: 'true',
    filter: dateFilter(),
    formatter: (cell, row) => formatDateforleadinout(cell)
  },
  {
    dataField: 'telecaller',
    text: 'Assign To',
    sort: 'true',
    filter: selectFilter({
      options: allEmployees.map(emp => ({
        value: emp.empId,
        label: `${emp.fname} ${emp.lname}`,
      })),
      placeholder: 'Select or Search by Name'
    }),
    formatter: (cell, row) => {
      const telecaller = allEmployees?.find(emp => emp?.empId === row?.telecaller);
      if (telecaller) {
        return `${telecaller.fname} ${telecaller.lname}`;
      } else {
        return 'Not Assigned'; // Or any other placeholder text
      }
    }
  }

    ,
    {
      dataField: 'source',
      text: 'Lead Source',
      sort: true,
      filter: textFilter({
        placeholder: "Enter Source (comma-separated)", // Guide the user
        onFilter: (filterValue, data) =>
          filterByMultipleLeadSource(filterValue, data),
      }),
      headerStyle: { minWidth: "120px" }, // Set minimum width
    },
  {
    dataField: 'actions', // Assuming 'actions' is the field in your data object that contains the actions data
    text: 'Actions',
    formatter: (cell, row, rowIndex, extraData) => {
      if (editableRowIndex === rowIndex) {
        return (
          <>
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => handleSave(row.LeadId, rowIndex)}
            >
              Save
            </button>
            <button
              className="btn btn-outline-primary"
              onClick={() => setEditableRowIndex(null)}
            >
              Cancel
            </button>
          </>
        );
      } else {
        return (
          <>
            <div className="d-flex">
              <div>
                <div className="mx-2 pointer-cursor">
                  <MdEdit
                    size={20}
                    color="black"
                    onClick={() => handleEditButtonClick(row)}
                  />
                </div>
              </div>
              {data?.role?.roleName === "SuperAdmin" &&(
              <div className="mx-2 pointer-cursor">
                <MdOutlineDelete
                  onClick={() => handleDelete(row)}
                  color="red"
                  size={20}
                />
              </div>
              )}
            </div>
            <div className="d-flex">
              <div className="mx-2 pointer-cursor">
                <FaWhatsapp
                  onClick={() => openwhatsApp(row?.mobile)}
                  size={20}
                  color="limegreen"
                />
              </div>
              <div className="ms-2 pointer-cursor">
                <MdEmail
                  style={{ cursor: "pointer" }}
                  onClick={() => openEamil(row.email)}
                  size={20}
                  color="#0072c6"
                />
              </div>
            </div>
          </>
        );
      }
    }
  }



  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    bgcolor: 'red'
  };

  // Function to handle row selection
  const handleRowSelect = (row, isSelected) => {

    setSelectedRows([...selectedRows, row])


  }; console.log(selectedRows)

  // Function to handle row selection for all rows
  const handleRowSelectAll = (isSelected, rows) => {
    // Update selectedRows state when all rows are selected or deselected
    console.log(rows)
    if (isSelected) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };
  //--------------data tabel--------------------------------------------



  const customPagination = paginationFactory({
    showTotal: true,
    sizePerPageList: [
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '500', value: 500 },
      { text: '1000', value: 1000 }, // ✅ Allow 1000 rows per page
      { text: '5000', value: 5000 }, // ✅ Allow 1000 rows per page
    ],
    sizePerPage: 50, // Default rows per page
  });

  return (
    <>



      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 card border-0">
            <p className="fs-3 fw-normal typed-text">Manage Leads</p>
          </div>


          <div className="card col-md-4 col-sm-12 border-0">
            <div className="d-flex flex-wrap align-items-center">
              {data?.role?.roleName === "AdminDm" && toggle ? (
                <button
                  type="button"
                  className="btn btn-warning btn-sm mr-2"
                  onClick={handleUpload}
                >
                  <FaCloudUploadAlt color={"black"} size={20} /> Upload
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-warning btn-sm mr-2"
                    onClick={handleButtonClick}
                  >
                    <FaFileDownload color={"black"} size={20} /> <span className="">Import</span>
                  </button>

                  <a href={leadsamplefile} download className="btn btn-warning btn-sm mr-2">
                    <FaFileExport color={"black"} size={20} /> Sample File
                  </a>

                  <button
                    type="button"
                    className="btn btn-warning btn-sm mr-2 mt-2"
                    onClick={() => handleAddlead()}
                  >
                    <FaAddressCard color={"black"} size={20} /> Add New Lead
                  </button>





                </>
              )}









            </div>
          </div>


          <div className="card col-md-4  col-sm-12 border-0 ">
            <select className="form-select border-0 mb-2" aria-label="Default select example"
              name="select"
              onChange={(e) => handleAssigneeUpdate(e.target.value)}

            >
              <option value="">Select Employee</option>
              {allEmployees.map((item) => (
                <option key={item.empId} value={item.empId}>
                  {`${item.fname} ${item.mname} ${item.lname} (${item.role})`}
                </option>
              ))}
            </select>





            <select
              className="form-select border-0 mb-2"
              aria-label="Default select example"
              onChange={(e) => {
                // Handle the update action when "Dead Lead" is selected
                if (e.target.value === "Dead Lead") {
                  handleBulkDead();
                }
              }}
            >
              <option>Select Action</option>
              <option value="Dead Lead">Dead Lead</option>
            </select>

          </div>



          <div className=" card col-md-12 border-0">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img src={loadinggif} alt="Loading" />
              </div>
            ) : (




              <ToolkitProvider
                keyField="SrNo"
                data={leadData}
                columns={columns}
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                search
              >
                {
                  props => (
                    <div>
                      <ExportCSVButton className="btn btn-success btn-sm mb-2 float-end" 
                      {...(props.csvProps || {})} filename="MantrasERP_Record.csv">
                        < SiMicrosoftexcel /> Export Record
                        </ExportCSVButton>

                      <div className="table-responsive cus-table">
                        <BootstrapTable
                          {...props.baseProps}
                          selectedRows={selectedRows}
                          filter={filterFactory()}
                          bootstrap4
                          keyField='SrNo'
                          data={leadData}
                          columns={columns}
                          striped
                          hover
                          condensed
                          pagination={customPagination} // ✅ Apply custom pagination here
                          selectRow={{
                            mode: 'checkbox',
                            clickToSelect: true,
                            onSelect: handleRowSelect,
                            onSelectAll: handleRowSelectAll,
                          }}
                          filterPosition="top"
                        />
                      </div>
                    </div>
                  )
                }
              </ToolkitProvider>






            )}</div>


          <div className="card col-md-12 border-0">

            <div className="card bg-light m-2">


              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              <EDModal
                isOpen={showModal}
                handleClose={() => setShowModal(false)}
                size="xl"
              >
                <EDLeadForm
                  handleSave={(obj) => handleSave(obj)}
                  leadObject={{ ...leadObject, remark: "" }}
                  setFileData={(audio) => setAdudioFile(audio)}
                  allRemarks={allRemarks}
                />
              </EDModal>

              <EDModal
                isOpen={addModal}
                handleClose={() => setAddModal(false)}
                size="xl"
              >
                <EDAddLead handleClose={(val) => setAddModal(false)} />
              </EDModal>
            </div>

          </div>


        </div>
      </div >










    </>
  );
};

export default Manage;
