// components/EDModal.js
import React from 'react';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { IoMdClose } from "react-icons/io";

const EDModal = (props) => {
  const { isOpen, handleClose, children } = props
  return (
    <div>
      <Modal isOpen={isOpen} toggle={handleClose} {...props}>
        <ModalBody>
          <IoMdClose className='float-end' size={25} onClick={() => handleClose(false)}/>
          {children}
        </ModalBody>
      </Modal>
    
    </div>

  )
}
// const EDModal = ({ visible, onClose, onSave, children }) => {
//   return (
//     <Modal  show={visible} onHide={onClose} centered size="lg">
//       <Modal.Header style={{ backgroundColor: '#3498db', color: 'white' }}>
//         <Modal.Title>Edit Lead</Modal.Title>
//         <Button variant="light" onClick={onClose}>
//           X 
//         </Button>
//       </Modal.Header>
//       <Modal.Body>{children}</Modal.Body>
//       <Modal.Footer>
//         <Button variant="primary" onClick={onSave}>
//           Save
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

export default EDModal;