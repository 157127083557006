import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { MdEdit, MdOutlineDelete, MdEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import edMantra from "../../services/edMantra";
import _ from "lodash";
import loadinggif from "../../assets/images/loading.gif";
import { FaAddressCard } from "react-icons/fa";
import { useSelector } from "react-redux";
import { SiMicrosoftexcel } from "react-icons/si";
import ToolkitProvider, {
  Search,
  CSVExport,
  pagination,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const InterviewScheduling = (props) => {
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const [showModal, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [allCandidate, setAllCandidate] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [openVacancy, setOpenVacancy] = useState([]);
  const [allInterview, setAllScheduledInterview] = useState([]);
  const [allRecruiters, setAllRecruiters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [companyName, setCompany] = useState(null);
  const [jobProfiles, setJobProfile] = useState([]);
  const [itemObj, setItemObj] = useState(null);
  const [loading, setLoading] = useState(true); // ✅ Add loading state
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const dropdownRef = useRef(null);
  const [interview, setInterView] = useState({
    companyId: "",
    interviewDate: "",
    candidateName: "",
    phone: "",
    companyName: "",
    jobProfile: "",
    candidateStatus: "",
  });
  const handleShow = () => {
    setShow(true);
    setReload(true);
  };
  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    setInterView({
      companyId: "",
      interviewDate: "",
      candidateName: "",
      phone: "",
      companyName: "",
      jobProfile: "",
      candidateStatus: "",
    });
  };

  const getAllRecruiters = async () => {
    try {
      const res = await edMantra.getAllRecruiters();
      setAllRecruiters([]);

      if (res?.data?.errorCode === 0) {
        setAllRecruiters(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Custom CSS for blinking effect
  const styles = `
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

  // Function to handle row selection
  const handleRowSelect = (row, isSelected) => {
    setSelectedRows([...selectedRows, row]);
  };
  console.log(selectedRows);

  // Function to handle row selection for all rows
  const handleRowSelectAll = (isSelected, rows) => {
    // Update selectedRows state when all rows are selected or deselected
    console.log(rows);
    if (isSelected) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };
  const openwhatsApp = async (phone) => {
    try {
      if (!phone.startsWith("+91")) {
        phone = "+91" + phone;
      }
      const whatsappUrl = `https://wa.me/${phone}`;
      window.open(whatsappUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const openEamil = (email) => {
    const recipient = email;
    const subject = "Subject of the email";
    const body = "Content of the email";
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  const formatDateforleadinout = (dateString) => {
    //for lead craetedAt and updatedAt
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Include this option for 12-hour format
    };
    return date.toLocaleDateString("en-GB", options);
  };

  // Filter candidates based on the search input
  const filteredCandidates = allCandidate.filter((item) =>
    `${item.firstName} ${item.lastName} (${item.candidateId})`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Handle candidate selection
  const handleSelect = (item) => {
    const fullName = `${item.firstName} ${item.lastName} (${item.candidateId})`;
    handleOnchange(fullName, "candidateName");
    handleOnchange(item.phone, "phone");
    setSearchTerm(fullName); // Set selected name in input
    setIsOpen(false); // Close dropdown
  };

  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "candidateName",
      text: "Candidate Name",
      sort: "true",
      filter: textFilter(),
    },

    {
      dataField: "phone",
      text: "Candidate Contact",
      sort: "true",
      filter: textFilter(),
    },

    {
      dataField: "email",
      text: "Email",
      filter: textFilter(),
    },

    {
      dataField: "companyName",
      text: "Company Name",
      sort: "true",
      filter: textFilter(),
    },

    {
      dataField: "jobProfile",
      text: "Job Profile",
      sort: "true",
      filter: textFilter(),
    },
    {
      dataField: "interviewDate",
      text: "Interview Date",
      sort: "true",
      filter: dateFilter(),
      formatter: (cell, row) => formatDateforleadinout(cell),
    },

    {
      dataField: "candidateStatus",
      text: "Candidate Status",
      sort: "true",
      filter: textFilter(),
    },

    {
      dataField: "createdBy",
      text: "Recruitment Manager",
      filter: selectFilter({
        options:
          allRecruiters.length > 0
            ? allRecruiters.map((emp) => ({
                value: emp.empId,
                label: `${emp.fname} ${emp.lname}`,
              }))
            : [],
        placeholder: "Select or Search by Name",
      }),
      formatter: (cell, row) => {
        const itemObj = allRecruiters.find(
          (emp) => emp.empId === row.createdBy
        );
        return (
          <div className="d-flex">
            {loading ? (
              <p>Loading...</p> // ✅ Show loading before data is fetched
            ) : itemObj ? (
              <p>{`${itemObj?.fname} ${itemObj?.lname}`}</p> // ✅ Show recruiter name
            ) : (
              <p>Data not available</p> // ✅ Show if recruiter not found
            )}
          </div>
        );
      },
    },

    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex">
          <MdEdit
            size={25}
            color="black"
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={() => handleEdit(row)}
          />
          <MdOutlineDelete
            size={25}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(row?.scheduleId)}
          />

          <div className="mx-2 pointer-cursor">
            <FaWhatsapp
              onClick={() => openwhatsApp(row?.mobile)}
              size={20}
              color="limegreen"
            />
          </div>
          <div className="ms-2 pointer-cursor">
            <MdEmail
              style={{ cursor: "pointer" }}
              onClick={() => openEamil(row.email)}
              size={20}
              color="#0072c6"
            />
          </div>
        </div>
      ),
    },
  ];

  const handleOnchange = (value, valueField) => {
    setInterView((prev) => ({
      ...prev,
      [valueField]: value,
    }));
  };

  const getAllCandidates = async () => {
    try {
      const result = await edMantra.getAllCandidates();
      setAllCandidate([]);
      console.log(result.data.data, "All candidate");
      if (result?.data?.errorCode === 0) {
        setAllCandidate(result?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllCompany = async () => {
    try {
      const result = await edMantra.getAllUniqueCompany({ empId: data?.empId });
      console.log("API Result: ", result?.data); // Log the response for debugging

      if (result?.data?.errorCode === 0) {
        setAllCompany(result?.data?.data); // Set company data if the API call is successful
      } else {
        console.error(result?.data?.message); // Log any errors
      }
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };

  const getOpenVacancy = async () => {
    try {
      const result = await edMantra.getOpenVacancy({
        companyId: interview?.companyId,
      });
      console.log(result, "Dekhte fahbdjvdvc ndvh cnsb"); // Log the response for debugging
      setOpenVacancy([]);
      if (result?.data?.errorCode === 0) {
        setOpenVacancy(result?.data?.data); // Set company data if the API call is successful
      } else {
        console.error(result?.data?.message); // Log any errors
      }
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };

  const getJobProfileByCompany = async () => {
    try {
      const result = await edMantra.getJobProfileByCompany({
        companyName,
        empId: data?.empId,
      });
      setJobProfile([]);

      if (result?.data?.errorCode === 0) {
        setJobProfile(result?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllScheduledInterview = async () => {
    try {
      const result = await edMantra.getAllScheduledInterview({
        empId: data?.empId,
      });
      setAllScheduledInterview([]);
      console.log(result.data.data, "All zzzzz");
      if (result?.data?.errorCode === 0) {
        setAllScheduledInterview(result?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpdateInterview = async () => {
    try {
      const res = await edMantra.updateInterviewScheduleById(interview);
      if (res?.data?.errorCode === 0) {
        toast.success(res?.data?.message);
        getAllCandidates();
        getAllCompany();
        getAllScheduledInterview();
        setShow(false);
        setInterView({
          interviewDate: "",
          candidateName: "",
          companyName: "",
          jobProfile: "",
          candidateStatus: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const result = await edMantra.addInterviewScheduling({
        ...interview,
        createdBy: data?.empId,
      });
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
        getAllCandidates();
        getAllCompany();
        getAllScheduledInterview();
        setShow(false);
        setInterView({
          interviewDate: "",
          candidateName: "",
          companyName: "",
          jobProfile: "",
          candidateStatus: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (row) => {
    setInterView(row);
    setShow(true);
    setIsEdit(true);
  };

  const handleDelete = async (scheduleId) => {
    try {
      const res = await edMantra.deleteInterviewById({ scheduleId });
      if (res.data?.errorCode === 0) {
        toast.success(res?.data?.message);
        getAllCandidates();
        getAllCompany();
        getAllScheduledInterview();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllCandidates();
    getAllScheduledInterview();
  }, []);

  useEffect(() => {
    getAllCompany();
  }, [reload]);

  useEffect(() => {
    getJobProfileByCompany();
  }, [companyName]);

  useEffect(() => {
    const fetchJobProfiles = async () => {
      try {
        const result = await edMantra.getAllUniqueCompany({
          empId: data?.empId,
        });
        const data = await result.json();
        setJobProfile(data); // Ensure you set the state correctly
      } catch (error) {
        console.error("Error fetching job profiles:", error);
      }
    };

    fetchJobProfiles();
  }, []);

  useEffect(() => {
    getOpenVacancy();
  }, [interview.companyId]);

  useEffect(() => {
    const fetchRecruiters = async () => {
      try {
        const response = await getAllRecruiters(); // Replace with actual API call
        if (response?.data) {
          setAllRecruiters(response.data);
        }
      } catch (error) {
        console.error("Error fetching recruiters:", error);
      } finally {
        setLoading(false); // ✅ Stop loading after data fetch
      }
    };

    fetchRecruiters();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 card border-0">
            <p className="fs-3 fw-normal typed-text">Schedule Interview</p>
          </div>

          <div className="card col-md-3 col-sm-12 border-0">
            <button
              type="button"
              className="btn btn-warning btn-sm"
              onClick={() => handleShow()}
            >
              <FaAddressCard color={"black"} size={20} /> Add Interview
            </button>
          </div>

          <div className="card col-md-12 border-0">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img src={loadinggif} alt="Loading" />
              </div>
            ) : (
              <ToolkitProvider
                keyField="companyId"
                data={allInterview}
                columns={columns}
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                search
              >
                {(props) => (
                  <div>
                    {(data?.role?.roleName === "SuperAdmin" ||
                      data?.role?.roleName === "AdminEM") && (
                      <ExportCSVButton
                        className="btn btn-success btn-sm mb-2 float-end"
                        {...(props.csvProps || {})}
                        filename="MantrasERP_Record.csv"
                      >
                        <SiMicrosoftexcel />
                        Export Record
                      </ExportCSVButton>
                    )}
                    <div className="table-responsive cus-table">
                      <style>{styles}</style>{" "}
                      {/* Inject the blinking effect CSS */}
                      <BootstrapTable
                        {...props.baseProps}
                        selectedRows={selectedRows}
                        filter={filterFactory()}
                        bootstrap4
                        keyField="companyId"
                        data={allInterview}
                        columns={columns}
                        striped
                        hover
                        condensed
                        pagination={paginationFactory({ showTotal: true })}
                        selectRow={{
                          mode: "checkbox",
                          clickToSelect: true,
                          onSelect: handleRowSelect,
                          onSelectAll: handleRowSelectAll,
                        }}
                        filterPosition="top"
                      />
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            )}
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <u>Schedule Interview Form</u>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <h6 className="custom-h6">Schedule Interview and Placement</h6>
          <div className="row">
            <div className="col-md-12">
              <p className="mb-0 fs-6"> Interview Date</p>
              <input
                placeholder="Date of Creation"
                className="form-control py-0 "
                type="date"
                value={interview.interviewDate}
                onChange={(e) =>
                  handleOnchange(e.target.value, "interviewDate")
                }
              />
            </div>
            <div className="col-md-12">
              <p className="mb-0 fs-6">Candidate Name</p>

              {/* <select
                name=""
                id=""
                className="form-select"
                value={interview.candidateName}
                onChange={(e) => {
                  const val = e.target.value;
                  // Find the candidate using the full name
                  const itemObj = allCandidate.find(
                    item => `${item.firstName} ${item.lastName} (${item.candidateId})` === val
                  );
                  handleOnchange(val, "candidateName"); // Set full name as candidateName
                  handleOnchange(itemObj?.phone, "phone"); // Set phone if itemObj found
                }}
              >
                <option value="">Select Candidate</option>
                {allCandidate.length > 0 &&
                  allCandidate.map((item, index) => (
                    <option key={index} value={`${item.firstName} ${item.lastName} (${item.candidateId})`}>
                      {item.phone} {item.firstName} {item.lastName}
                    </option>
                  ))}
              </select> */}
              <div ref={dropdownRef} className="position-relative w-100">
                {/* Search Input */}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Candidate..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsOpen(true);
                  }}
                  onFocus={() => setIsOpen(true)}
                />

                {/* Dropdown List */}
                {isOpen && (
                  <ul
                    className="list-group position-absolute w-100 bg-white shadow-sm border rounded"
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      zIndex: 10,
                    }}
                  >
                    {filteredCandidates.length > 0 ? (
                      filteredCandidates.map((item, index) => (
                        <li
                          key={index}
                          className="list-group-item list-group-item-action"
                          onClick={() => handleSelect(item)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.phone} {item.firstName} {item.lastName}
                        </li>
                      ))
                    ) : (
                      <li className="list-group-item text-muted">
                        No results found
                      </li>
                    )}
                  </ul>
                )}
              </div>

              <p className="mb-0 fs-6">Company Name</p>
              <select
                name=""
                id=""
                className="form-select"
                value={interview.companyId}
                onChange={(e) => {
                  const val = e.target.value;
                  const itemObj = allCompany.find(
                    (item) => item.companyId === val
                  );
                  handleOnchange(e.target.value, "companyId");
                  handleOnchange(itemObj.companyName, "companyName");
                }}
              >
                <option value="">Select Company</option>
                {allCompany.length > 0 &&
                  allCompany.map((item) => (
                    <>
                      <option value={item?.companyId} key={item?.companyId}>
                        {item?.companyName}
                      </option>
                    </>
                  ))}
              </select>
              {_.isNil(interview?.companyId) ||
              _.isEmpty(interview?.companyId) ? null : (
                <>
                  <p className="mb-0 fs-6">Job Profile</p>
                  <select
                    name=""
                    id=""
                    className="form-select"
                    value={interview.jobProfile}
                    onChange={(e) =>
                      handleOnchange(e.target.value, "jobProfile")
                    }
                  >
                    <option value="">Select Job Profile</option>
                    {openVacancy.length > 0 &&
                      openVacancy.map((item) => (
                        <option value={item?.jobProfile} key={item?.jobProfile}>
                          {item?.jobProfile}
                        </option>
                      ))}
                  </select>
                </>
              )}
            </div>
            <div className="col-md-12">
              <p className="mb-0 fs-6">Candidate Status</p>

              <select
                name=""
                id=""
                className="form-select"
                value={interview.candidateStatus}
                onChange={(e) =>
                  handleOnchange(e.target.value, "candidateStatus")
                }
              >
                <option value="">Select Status</option>
                <option value="CV Submitted">CV Submitted</option>
                <option value="Shortlisted">Shortlisted</option>
                <option value="Interviewed">Interviewed</option>
                <option value="Selected">Selected</option>
                <option value="Hold">Hold</option>
                <option value="Rejected">Rejected</option>
                <option value="Joined">Joined</option>
                <option value="Terminated">Terminated</option>
                <option value="Working">Working</option>
                <option value="Registered">Registered</option>
                <option value="Not Joined">Not Joined</option>
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} size="sm">
            Close
          </Button>
          {isEdit ? (
            <Button variant="primary" onClick={handleUpdateInterview} size="sm">
              Update Interview
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit} size="sm">
              Schedule Interview
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InterviewScheduling;
