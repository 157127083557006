import React, { useState, useEffect } from "react";
import Select from "react-select";

const MultiSelectFilter = ({ onFilter, column, options = [] }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    // ✅ Ensure all selected values are strings and lowercase
    const selectedValues = selectedOptions.map((opt) => opt.value.toString().toLowerCase());

    console.log(`Applying filter for ${column?.text}:`, selectedValues);

    // ✅ Always call onFilter, even when clearing filters
    onFilter(selectedValues);
  }, [selectedOptions]);

  return (
    <Select
      options={options}
      isMulti
      isSearchable
      placeholder={`Search and select ${column?.text || "Options"}...`}
      value={selectedOptions}
      onChange={(options) => setSelectedOptions(options || [])} // ✅ Allows clearing selections
      isClearable // ✅ Enables clear button in dropdown
    />
  );
};

export default MultiSelectFilter;
