import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { numberFilter } from "react-bootstrap-table2-filter";
import { MultiSelect } from "react-multi-select-component";
import MultiSelectFilter from "../../Components/Filter/MultiSelectFilter"; // Import the reusable filter
import { SiMicrosoftexcel } from "react-icons/si";
import filterFactory, {
  textFilter,
  selectFilter,
  customFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
  CSVExport,
  pagination,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaWhatsapp, FaDownload } from "react-icons/fa";
import { MdEdit, MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import candidateUpload from "../../assets/files/ATSCandidate.xlsx";
import edMantra from "../../services/edMantra";
import _ from "lodash";
import loadinggif from "../../assets/images/loading.gif";
import {
  FaFileDownload,
  FaFileExport,
  FaAddressCard,
  FaCloudUploadAlt,
  FaFilter,
} from "react-icons/fa";
import axios from "axios";

const CandidateManagement = (props) => {
  const sourceOptions = [
    "Activities",
    "Admission Centre",
    "Alumni",
    "Apna App",
    "Coaching's",
    "Dizital Mantras",
    "DM Student",
    "Employee Reference",
    "Employment Mantras",
    "Event",
    "Field Data",
    "Franchise",
    "GMB",
    "Google Ad",
    "Incoming Call",
    "Intern",
    "Job Fair",
    "Just Dial",
    "LinkedIn",
    "Meta Ad",
    "Naukari Portal",
    "Purchased Data",
    "Reference",
    "Schools",
    "Self-Generated",
    "Seminar",
    "Walk-In",
    "Website",
    "Webinar",
    "WhatsApp",
    "WhatsApp Group",
    "WhatsApp Status",
  ];
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const [aadharFile, setAadharFile] = useState(null);
  const [agreementFile, setAgreementFile] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const [photo, setphoto] = useState(null);
  const [showModal, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [reload, setReload] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const fileInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSource, setSelectedSource] = useState("");
  const [file, setFile] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [personName, setpersonName] = useState("");
  const [personContact, setpersonContact] = useState("");
  const [tableData, setTableData] = useState([]); // ✅ Ensure it's an array
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filters, setFilters] = useState({});
  const [candidateData, setCandidateData] = useState([]); // ✅ This should always be an array

  const [candidate, setCandidate] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    maritalStatus: "",
    email: "",
    phone: "",
    dob: "",
    highestQualification: "",
    otherQualification: "",
    experience: "",
    sector: "",
    role: "",
    keySkills: "",
    language: [], // Initialize as an empty array
    employer: "",
    designation: "",
    yearsOfExperience: "",
    monthsOfExperience: "",
    currentCTC: "",
    expectedCTC: "",
    registeredDate: "",
    source: "",
    personName: "",
    personContact: "",
    residentialAddress: "",
    mailingAddress: "",
    residentialCity: "",
    mailingCity: "",
  });
  const [selectedLanguages, setSelectedLanguages] = useState({
    language: [], // Initialize as an empty array
  });

  const handleChange = (selectedOptions) => {
    setCandidate((prevState) => ({
      ...prevState,
      language: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (e, index) => {
    setFile(e.target.files[0]);
    setToggle(!toggle);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const result = await edMantra.uploadCandidate(formData);

      // If API call succeeds and no errors
      if (result.data.errorCode === 0) {
        setToggle(false);
        setRefresh(!refresh);
        toast.success(result?.data?.message);
        setFile({});
      }
    } catch (error) {
      // Default error message
      let errorMessage =
        "An unexpected error occurred. Please try again later.";

      // Check for a specific error response from the backend (response.data.message)
      if (error?.response?.data?.message) {
        // Check if it contains duplicate phone numbers
        if (
          error.response.data.errorCode === 1 &&
          error.response.data.data?.duplicateNumbers
        ) {
          const duplicateNumbers = error.response.data.data.duplicateNumbers;
          if (duplicateNumbers && duplicateNumbers.length > 0) {
            errorMessage = `Duplicate phone numbers found: ${duplicateNumbers.join(
              ", "
            )}. Please remove them from the file and try again.`;
          }
        }
      }

      toast.error(errorMessage); // Show the error message with duplicates
      console.error("Error uploading files:", error);
    }
  };

  const languageOptions = [
    { value: "Assamese", label: "Assamese" },
    { value: "Bengali", label: "Bengali" },
    { value: "Bodo", label: "Bodo" },
    { value: "Dogri", label: "Dogri" },
    { value: "English", label: "English" },
    { value: "Gujarati", label: "Gujarati" },
    { value: "Hindi", label: "Hindi" },
    { value: "Kannada", label: "Kannada" },
    { value: "Kashmiri", label: "Kashmiri" },
    { value: "Konkani", label: "Konkani" },
    { value: "Maithili", label: "Maithili" },
    { value: "Malayalam", label: "Malayalam" },
    { value: "Manipuri", label: "Manipuri" },
    { value: "Marathi", label: "Marathi" },
    { value: "Nepali", label: "Nepali" },
    { value: "Odia", label: "Odia" },
    { value: "Punjabi", label: "Punjabi" },
    { value: "Sanskrit", label: "Sanskrit" },
    { value: "Santali", label: "Santali" },
    { value: "Sindhi", label: "Sindhi" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
    { value: "Urdu", label: "Urdu" },
  ];

  const [candidateList, setCandidateList] = useState([]);
  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    setCandidate({
      firstName: "",
      lastName: "",
      gender: "",
      maritalStatus: "",
      email: "",
      phone: "",
      dob: "",
      highestQualification: "",
      otherQualification: "",
      experience: "",
      sector: "",
      role: "",
      employer: "",
      designation: "",
      yearsOfExperience: "",
      monthsOfExperience: "",
      currentCTC: "",
      expectedCTC: "",
      registeredDate: "",
      source: "",
      personName: "",
      personContact: "",
      residentialAddress: "",
      mailingAddress: "",
      residentialCity: "",
      mailingCity: "",
    });
  };

  const handleAadharDownlaod = async (fileName) => {
    console.log(fileName, "FileFileFileFileFileFile");
    try {
      //const response = await axios.post('http://localhost:5000/api/download',
      const response = await axios.post(
        "https://mantraserp.com/api/download",
        { filename: fileName },
        { responseType: "blob" } // Important for binary data
      );

      // Create a URL for the blob and trigger a download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set the default file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  const handleOnchange = (value, valueField) => {
    setCandidate((prev) => ({
      ...prev,
      [valueField]: value,
    }));

    if (valueField === "source") {
      setSelectedSource(value);
    }

    if (valueField === "personName") {
      setpersonName(value);
    }
    if (valueField === "personContact") {
      setpersonContact(value);
    }
  };

  // const getAllCandidates = async () => {
  //   const result = await edMantra.getAllCandidates();
  //   setCandidateList(result?.data?.data);
  // };

  const getAllCandidates = async () => {
    try {
      const result = await edMantra.getAllCandidates();
      if (Array.isArray(result?.data?.data)) {
        setCandidateList(result.data.data);
        setCandidateData(result.data.data);
        setTableData(result.data.data);
      } else {
        console.error(
          "Error: API response is not an array",
          result?.data?.data
        );
        setCandidateList([]);
        setCandidateData([]);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching candidates:", error);
      setCandidateList([]);
      setCandidateData([]);
      setTableData([]); // ✅ Prevents crashes
    }
  };

  useEffect(() => {
    getAllCandidates();
  }, [reload]);

  useEffect(() => {
    if (Array.isArray(candidateData) && candidateData.length > 0) {
      setTableData(candidateData);
    } else {
      setTableData([]); // ✅ Prevents undefined issues
    }
  }, [candidateData]);

  const handleDelete = async (row) => {
    await edMantra
      .deleteCandidateById(row)
      .then((res) => {
        toast(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setReload((reload) => !reload);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openWhatsApp = async (phone) => {
    try {
      if (!phone.startsWith("+91")) {
        phone = "+91" + phone; // Modify the existing phone variable
      }
      const whatsappUrl = `https://wa.me/${phone}`;
      window.open(whatsappUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (row) => {
    setCandidate(row);
    setShow(true);
    setIsEdit(true);
  };

  const formatDateforleadinout = (dateString) => {
    //for lead craetedAt and updatedAt
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Include this option for 12-hour format
    };
    return date.toLocaleDateString("en-GB", options);
  };

  const calculateAge = (dob) => {
    if (!dob) return ""; // Handle empty values
    const birthYear = new Date(dob).getFullYear();
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };

  // Function to filter data based on selected values
  const filterByMultipleValues = (filterValue, data = [], field) => {
    if (!Array.isArray(data)) {
      console.error("Error: data is not an array", data);
      return [];
    }

    if (!filterValue || filterValue.length === 0) return data;

    return data.filter((row) => {
      const rowValue = row[field]?.toString().toLowerCase() || "";

      // ✅ Ensure at least one of the selected values matches exactly
      return filterValue.some((val) => rowValue === val.toLowerCase());
    });
  };

  // Function to apply filtering when filter changes
  const handleFiltering = (field, selectedValues) => {
    const updatedFilters = { ...filters, [field]: selectedValues };
    setFilters(updatedFilters);

    let filteredData = [...candidateData]; // ✅ Start with the full dataset

    Object.keys(updatedFilters).forEach((key) => {
      if (updatedFilters[key]?.length > 0) {
        filteredData = filterByMultipleValues(
          updatedFilters[key],
          filteredData,
          key
        );
      }
    });

    // ✅ Reset tableData when no filters are applied
    setTableData(
      Object.values(updatedFilters).every((val) => val.length === 0)
        ? candidateData
        : filteredData
    );
  };

  const filterByMultipleSkills = (filterValue, data) => {
    if (!filterValue) return data; // Return all data if no filter is applied

    const selectedSkills = filterValue
      .split(",") // Split input by commas
      .map((skill) => skill.trim().toLowerCase()); // Trim spaces & convert to lowercase

    return data.filter((row) => {
      // Convert row keySkills to an array & lowercase
      const rowSkills = row.keySkills
        .split(",") // Assuming skills are stored as "Java, React, Node.js"
        .map((skill) => skill.trim().toLowerCase());

      // Check if any of the selectedSkills exist in rowSkills
      return selectedSkills.some((skill) => rowSkills.includes(skill));
    });
  };

  const sectorFilter = customFilter();
  const sectorOptions = [
    {
      value: "Accounts or CA or CS or Taxation",
      label: "Accounts or CA or CS or Taxation",
    },
    { value: "Adhesives", label: "Adhesives" },
    { value: "Agency or Consulting", label: "Agency or Consulting" },
    { value: "Agriculture", label: "Agriculture" },
    { value: "Any Official", label: "Any Official" },
    { value: "Automobile", label: "Automobile" },
    { value: "Aviation", label: "Aviation" },
    { value: "Banking", label: "Banking" },
    {
      value: "Biotech or R&D or Scientist",
      label: "Biotech or R&D or Scientist",
    },
    { value: "BPO", label: "BPO" },
    { value: "Chemical", label: "Chemical" },
    {
      value: "Distribution & Supply Chain",
      label: "Distribution & Supply Chain",
    },
    { value: "Education", label: "Education" },
    { value: "Energy", label: "Energy" },
    { value: "Engineering Projects", label: "Engineering Projects" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Export or Import", label: "Export or Import" },
    { value: "Finance or Micro Finance", label: "Finance or Micro Finance" },
    { value: "Financial Services", label: "Financial Services" },
    { value: "FMCG", label: "FMCG" },
    { value: "Food", label: "Food" },
    { value: "Glass", label: "Glass" },
    { value: "Govt. Sector", label: "Govt. Sector" },
    { value: "Home Furnishing", label: "Home Furnishing" },
    { value: "Hospital or Healthcare", label: "Hospital or Healthcare" },
    { value: "Hotels or Restaurants", label: "Hotels or Restaurants" },
    { value: "Immigration", label: "Immigration" },
    { value: "Insurance", label: "Insurance" },
    { value: "IT Hardware", label: "IT Hardware" },
    { value: "IT Software", label: "IT Software" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Media and Advertising", label: "Media and Advertising" },
    { value: "Oil & Lubricants", label: "Oil & Lubricants" },
    {
      value: "Online Marketing or Digital Marketing",
      label: "Online Marketing or Digital Marketing",
    },
    { value: "Paint", label: "Paint" },
    { value: "Pharmaceutical", label: "Pharmaceutical" },
    { value: "Plastic or Pet", label: "Plastic or Pet" },
    { value: "Plywood & Laminates", label: "Plywood & Laminates" },
    { value: "Publishing & Printing", label: "Publishing & Printing" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Retail", label: "Retail" },
    { value: "Technology", label: "Technology" },
    { value: "Telecom", label: "Telecom" },
    { value: "Transport Logistics", label: "Transport Logistics" },
    {
      value: "Transport or Tour & Travel",
      label: "Transport or Tour & Travel",
    },
    { value: "Warehouse or Inventory", label: "Warehouse or Inventory" },
    { value: "Other", label: "Other" },
  ];
  const roleFilter = customFilter();
  const roleOptions = [
    { value: "Account Executive", label: "Account Executive" },
    { value: "Account Manager", label: "Account Manager" },
    { value: "Admission Coordinator", label: "Admission Coordinator" },
    { value: "Admin Executive", label: "Admin Executive" },
    { value: "Admin Manager", label: "Admin Manager" },
    { value: "Area Sales Manager (ASM)", label: "Area Sales Manager (ASM)" },
    { value: "Article Assistant", label: "Article Assistant" },
    { value: "Assistant Manager", label: "Assistant Manager" },
    { value: "Auditor", label: "Auditor" },
    { value: "Autocad Engineer", label: "Autocad Engineer" },
    {
      value: "Backend or Back Office Executive or Assistant Executive",
      label: "Backend or Back Office Executive or Assistant Executive",
    },
    { value: "Banquet Manager", label: "Banquet Manager" },
    { value: "Billing Executives", label: "Billing Executives" },
    { value: "Block Officer", label: "Block Officer" },
    { value: "Boiler Operator", label: "Boiler Operator" },
    { value: "Brand Manager", label: "Brand Manager" },
    { value: "Business Analyst", label: "Business Analyst" },
    {
      value: "Business Development Executive (BDE)",
      label: "Business Development Executive (BDE)",
    },
    {
      value: "Business Development Manager (BDM)",
      label: "Business Development Manager (BDM)",
    },
    { value: "Cashier", label: "Cashier" },
    { value: "Civil Engineer", label: "Civil Engineer" },
    { value: "Clinical Assistants", label: "Clinical Assistants" },
    { value: "Collection Executives", label: "Collection Executives" },
    { value: "Commodity Dealer", label: "Commodity Dealer" },
    { value: "Company Secretary", label: "Company Secretary" },
    { value: "Computer Operator", label: "Computer Operator" },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Counsellor", label: "Counsellor" },
    { value: "Credit Manager", label: "Credit Manager" },
    {
      value: "Customer Care Executive (CCE)",
      label: "Customer Care Executive (CCE)",
    },
    {
      value: "Customer Relationship Officer",
      label: "Customer Relationship Officer",
    },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "Deputy Manager", label: "Deputy Manager" },
    { value: "Digital Marketer", label: "Digital Marketer" },
    { value: "Electrical Engineer", label: "Electrical Engineer" },
    { value: "Electrician", label: "Electrician" },
    { value: "Equity Dealer", label: "Equity Dealer" },
    { value: "Export Manager", label: "Export Manager" },
    { value: "Fashion Designer", label: "Fashion Designer" },
    { value: "Finance Executive", label: "Finance Executive" },
    { value: "Finance Officer", label: "Finance Officer" },
    { value: "Financial Advisor", label: "Financial Advisor" },
    {
      value: "Food & Beverages Executive",
      label: "Food & Beverages Executive",
    },
    { value: "Food & Beverages Manager", label: "Food & Beverages Manager" },
    { value: "Front Office Executive", label: "Front Office Executive" },
    { value: "GM/Unit Head", label: "GM/Unit Head" },
    { value: "Graphics Designer", label: "Graphics Designer" },
    { value: "Hotel Captain", label: "Hotel Captain" },
    { value: "Hotel Manager", label: "Hotel Manager" },
    { value: "HR Executive", label: "HR Executive" },
    { value: "HR Manager", label: "HR Manager" },
    { value: "Inventory Manager", label: "Inventory Manager" },
    { value: "IT Security Coordinator", label: "IT Security Coordinator" },
    { value: "IT Trainer", label: "IT Trainer" },
    { value: "ITI Fitter", label: "ITI Fitter" },
    { value: "Lab Technician", label: "Lab Technician" },
    { value: "Law Officer", label: "Law Officer" },
    { value: "Liasoning Manager", label: "Liasoning Manager" },
    { value: "Logistic Manager", label: "Logistic Manager" },
    { value: "Machine Operator", label: "Machine Operator" },
    { value: "Maintenance Engineer", label: "Maintenance Engineer" },
    { value: "Mechanical Engineer", label: "Mechanical Engineer" },
    { value: "Medical Representative", label: "Medical Representative" },
    { value: "Merchandiser", label: "Merchandiser" },
    { value: "MIS Executive", label: "MIS Executive" },
    { value: "Networking Manager", label: "Networking Manager" },
    { value: "Nurse", label: "Nurse" },
    { value: "Operation Manager", label: "Operation Manager" },
    { value: "Pathologist", label: "Pathologist" },
    { value: "Pharmacist", label: "Pharmacist" },
    { value: "Placement Officer", label: "Placement Officer" },
    { value: "Plant Planning Manager", label: "Plant Planning Manager" },
    { value: "Plant Supervisor", label: "Plant Supervisor" },
    { value: "PRO", label: "PRO" },
    { value: "Production Manager", label: "Production Manager" },
    {
      value: "Project Manager or Team Leader",
      label: "Project Manager or Team Leader",
    },
    { value: "Purchase Executive", label: "Purchase Executive" },
    { value: "Quality Analyst", label: "Quality Analyst" },
    {
      value: "Quality Control or Process Control",
      label: "Quality Control or Process Control",
    },
    { value: "Receptionist", label: "Receptionist" },
    { value: "Regional Manager", label: "Regional Manager" },
    { value: "Restaurant Manager", label: "Restaurant Manager" },
    { value: "Safety Officer", label: "Safety Officer" },
    {
      value: "Sales Manager or Marketing Manager",
      label: "Sales Manager or Marketing Manager",
    },
    {
      value: "Sales or Marketing Executive",
      label: "Sales or Marketing Executive",
    },
    { value: "SAP or ERP Developer", label: "SAP or ERP Developer" },
    { value: "SEO Executive or Manager", label: "SEO Executive or Manager" },
    { value: "Service Delivery Manager", label: "Service Delivery Manager" },
    { value: "Site Engineer", label: "Site Engineer" },
    { value: "Social Media Marketing", label: "Social Media Marketing" },
    { value: "Soft Skill Trainer", label: "Soft Skill Trainer" },
    { value: "Software Developer", label: "Software Developer" },
    { value: "Store Manager", label: "Store Manager" },
    { value: "Supervisor", label: "Supervisor" },
    { value: "T&D Manager", label: "T&D Manager" },
    {
      value: "Teacher or Lecturer or Trainer",
      label: "Teacher or Lecturer or Trainer",
    },
    { value: "Team Leader", label: "Team Leader" },
    { value: "Technical Sales", label: "Technical Sales" },
    { value: "Telecaller", label: "Telecaller" },
    { value: "Video Editor", label: "Video Editor" },
    { value: "Vice President", label: "Vice President" },
    { value: "Waiter or Steward", label: "Waiter or Steward" },
    { value: "Warehouse Manager", label: "Warehouse Manager" },
    { value: "Web Designer", label: "Web Designer" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Zonal Manager", label: "Zonal Manager" },
    { value: "Other", label: "Other" },
  ];

  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "firstName",
      text: "Full Name",
      filter: textFilter(),
      formatter: (cell, row) => (
        <p>
          {row?.firstName || row?.lastName
            ? `${row?.firstName || ""} ${row?.lastName || ""}`.trim()
            : ""}
        </p>
      ),
      headerStyle: { minWidth: "120px" }, // Set minimum width
    },
    {
      dataField: "gender",
      text: "Gender",
      filter: textFilter(),
    },
    {
      dataField: "maritalStatus",
      text: "Marital Status",
      filter: textFilter(),
    },
    {
      dataField: "email",
      text: "Email",
      filter: textFilter(),
    },
    {
      dataField: "phone",
      text: "Phone No",
      filter: textFilter(),
    },
    {
      dataField: "dob",
      text: "Age",
      formatter: (cell) => calculateAge(cell),
      filter: numberFilter({
        placeholder: "Enter Age",
        comparatorClassName: "custom-comparator",
        comparators: ["=", ">", "<", ">=", "<=", "!="],
      }),
      headerStyle: { minWidth: "100px" }, // Set minimum width
    },

    {
      dataField: "highestQualification",
      text: "Highest Qualification",
      filter: textFilter(),
    },
    {
      dataField: "sector",
      text: "Industry",
      filter: sectorFilter,
      filterRenderer: (onFilter, column) => (
        <MultiSelectFilter
          onFilter={(selectedValues) =>
            handleFiltering("sector", selectedValues)
          }
          column={column}
          options={sectorOptions} // ✅ Ensure correct sector options
        />
      ),
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: "role",
      text: "Role",
      filter: roleFilter,
      filterRenderer: (onFilter, column) => (
        <MultiSelectFilter
          onFilter={(selectedValues) => handleFiltering("role", selectedValues)}
          column={column}
          options={roleOptions} // ✅ Ensure correct role options
        />
      ),
      headerStyle: { minWidth: "120px" }, // Set minimum width
    },
    {
      dataField: "keySkills",
      text: "Key Skills",
      filter: textFilter({
        placeholder: "Enter skills (comma-separated)", // Guide the user
        onFilter: (filterValue, data) =>
          filterByMultipleSkills(filterValue, data),
      }),
    },

    {
      dataField: "yearsOfExperience",
      text: "Years Of Experience (Total)",
      filter: numberFilter({
        placeholder: "Enter experience",
        comparatorClassName: "custom-comparator",
        comparators: ["=", ">", "<", ">=", "<=", "!="],
      }),
    },
    {
      dataField: "currentCTC",
      text: "Current CTC",
      filter: numberFilter({
        placeholder: "Enter experience",
        comparatorClassName: "custom-comparator",
        comparators: ["=", ">", "<", ">=", "<=", "!="],
      }),
      headerStyle: { minWidth: "120px" }, // Set minimum width
    },
    {
      dataField: "expectedCTC",
      text: "Expected CTC",
      filter: numberFilter({
        placeholder: "Enter experience",
        comparatorClassName: "custom-comparator",
        comparators: ["=", ">", "<", ">=", "<=", "!="],
      }),
      headerStyle: { minWidth: "120px" }, // Set minimum width
    },
    {
      dataField: "mailingCity",
      text: "Mailing City",
      filter: textFilter(),
    },

    {
      dataField: "aadharDocName",
      text: "Aadhar",
      formatter: (cell, row) => (
        <div className="d-flex">
          <a href="##" onClick={() => handleAadharDownlaod(row.aadharDocName)}>
            {row.aadharDocName} <FaDownload />
          </a>
        </div>
      ),
    },

    {
      dataField: "agreementDocName",
      text: "Agreement",
      formatter: (cell, row) => (
        <div className="d-flex">
          <a
            href="##"
            onClick={() => handleAadharDownlaod(row.agreementDocName)}
          >
            {row.agreementDocName} | <FaDownload />
          </a>
        </div>
      ),
    },
    {
      dataField: "resumeDocName",
      text: "Resume",
      formatter: (cell, row) => (
        <div className="d-flex">
          <a href="##" onClick={() => handleAadharDownlaod(row.resumeDocName)}>
            {row.resumeDocName}
          </a>
        </div>
      ),
    },
    {
      dataField: "latestPhoto",
      text: "Photo",
      formatter: (cell, row) => (
        <div className="d-flex">
          <a href="##" onClick={() => handleAadharDownlaod(row.latestPhoto)}>
            {row.latestPhoto}
          </a>
        </div>
      ),
    },
    {
      dataField: "createdAt",
      text: "Create Time",
      sort: "true",
      filter: dateFilter(),
      formatter: (cell, row) => formatDateforleadinout(cell),
    },
    {
      dataField: "updatedAt",
      text: "Update Time",
      sort: "true",
      filter: dateFilter(),
      formatter: (cell, row) => formatDateforleadinout(cell),
    },

    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex">
          <FaWhatsapp
            onClick={() => openWhatsApp(row?.phone)}
            size={20}
            color="limegreen"
          />

          <MdEdit
            size={25}
            color="black"
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={() => handleEdit(row)}
          />
          <MdOutlineDelete
            size={25}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(row)}
          />
        </div>
      ),
    },
  ];

  const handleAddCandidate = async () => {
    const formData = new FormData();
    formData.append("resume", resumeFile);
    formData.append("aadhar", aadharFile);
    formData.append("agreement", agreementFile);
    formData.append("photo", photo);
    formData.append("firstName", candidate.firstName);
    formData.append("lastName", candidate.lastName);
    formData.append("gender", candidate.gender);
    formData.append("maritalStatus", candidate.maritalStatus);
    formData.append("email", candidate.email);
    formData.append("phone", candidate.phone);
    formData.append("dob", candidate.dob);
    formData.append("highestQualification", candidate.highestQualification);
    formData.append("otherQualification", candidate.otherQualification);
    formData.append("experience", candidate.experience);
    formData.append("sector", candidate.sector);
    formData.append("role", candidate.role);
    formData.append("keySkills", candidate.keySkills);
    formData.append("language", JSON.stringify(candidate.language)); // Serialize array to JSON string
    formData.append("employer", candidate.employer);
    formData.append("designation", candidate.designation);
    formData.append("yearsOfExperience", candidate.yearsOfExperience);
    formData.append("currentCTC", candidate.currentCTC);
    formData.append("expectedCTC", candidate.expectedCTC);
    formData.append("registeredDate", candidate.registeredDate);
    formData.append("source", candidate.source);
    formData.append("personName", candidate.personName);
    formData.append("personContact", candidate.personContact);
    formData.append("residentialAddress", candidate.residentialAddress);
    formData.append("residentialCity", candidate.residentialCity);
    formData.append("mailingAddress", candidate.mailingAddress);
    formData.append("mailingCity", candidate.mailingCity);

    try {
      //const response = await fetch("http://localhost:5000/api/registerCandidate", // for dev env.
      const response = await fetch(
        "https://mantraserp.com/api/registerCandidate", // for ive production
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const result = await response.json();
      if (result?.errorCode === 0) {
        setAadharFile(null);
        setAgreementFile(null);
        setResumeFile(null);
        setphoto(null);
        setShow(false);
        toast.success(result?.message);
        setShow(false);
        getAllCandidates();
        setCandidate({
          firstName: "",
          lastName: "",
          gender: "",
          maritalStatus: "",
          email: "",
          phone: "",
          dob: "",
          highestQualification: "",
          otherQualification: "",
          experience: "",
          sector: "",
          role: "",
          employer: "",
          designation: "",
          yearsOfExperience: "",
          monthsOfExperience: "",
          currentCTC: "",
          expectedCTC: "",
          registeredDate: "",
          source: "",
          personName: "",
          personContact: "",
          residentialAddress: "",
          mailingAddress: "",
          residentialCity: "",
          mailingCity: "",
        });
        getAllCandidates(); // Refresh candidate list
      } else {
        // Handle API-specific error message
        toast.error(result?.message || "Something went wrong");
      }
    } catch (error) {
      // User-friendly error messages
      let errorMessage =
        "An unexpected error occurred. Please try again later.";

      // Check if the error has a response with a message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error.message.includes("Failed to upload files")) {
        errorMessage =
          "The phone number you entered is already registered. Please use a different phone number.";
      } else if (error.message.includes("phone must be unique")) {
        errorMessage =
          "Unable to register the candidate at the moment. Please check your network or try again.";
      }

      toast.error(errorMessage);
      console.error("Error uploading files:", error);
    }
  };

  const handleEditCandidate = async () => {
    const formData = new FormData();
    formData.append("candidateId", candidate.candidateId);
    formData.append("resume", resumeFile);
    formData.append("aadhar", aadharFile);
    formData.append("agreement", agreementFile);
    formData.append("photo", photo);
    formData.append("firstName", candidate.firstName);
    formData.append("lastName", candidate.lastName);
    formData.append("gender", candidate.gender);
    formData.append("maritalStatus", candidate.maritalStatus);
    formData.append("email", candidate.email);
    formData.append("phone", candidate.phone);
    formData.append("dob", candidate.dob);
    formData.append("highestQualification", candidate.highestQualification);
    formData.append("otherQualification", candidate.otherQualification);
    formData.append("experience", candidate.experience);
    formData.append("sector", candidate.sector);
    formData.append("role", candidate.role);
    formData.append("keySkills", candidate.keySkills);
    formData.append("language", JSON.stringify(candidate.language)); // Serialize array to JSON string
    formData.append("employer", candidate.employer);
    formData.append("designation", candidate.designation);
    formData.append("yearsOfExperience", candidate.yearsOfExperience);
    formData.append("currentCTC", candidate.currentCTC);
    formData.append("expectedCTC", candidate.expectedCTC);
    formData.append("registeredDate", candidate.registeredDate);
    formData.append("source", candidate.source);
    formData.append("personName", candidate.personName);
    formData.append("personContact", candidate.personContact);
    formData.append("residentialAddress", candidate.residentialAddress);
    formData.append("residentialCity", candidate.residentialCity);
    formData.append("mailingAddress", candidate.mailingAddress);
    formData.append("mailingCity", candidate.mailingCity);
    // try {
    //   const result = await edMantra.updateCandidateById(candidate);
    //   if (result?.data?.errorCode === 0) {
    // toast.success(result?.data?.message);
    // setShow(false);
    // setIsEdit(false);
    // getAllCandidates();
    // setCandidate({
    //   firstName: "",
    //   lastName: "",
    //   gender: "",
    //   maritalStatus: "",
    //   email: "",
    //   phone: "",
    //   dob: "",
    //   highestQualification: "",
    //   otherQualification: "",
    //   experience: "",
    //   sector: "",
    //   role: "",
    //   employer: "",
    //   designation: "",
    //   yearsOfExperience: "",
    //   monthsOfExperience: "",
    //   currentCTC: "",
    //   expectedCTC: "",
    //   registeredDate: "",
    //   source: "",
    //   personName: "",
    //   personContact: "",
    //   residentialAddress: "",
    //   mailingAddress: "",
    //   residentialCity: "",
    //   mailingCity: "",

    // });
    //   }
    // } catch (error) {
    //   console.error(error);
    // }

    try {
      //const response = await fetch("http://localhost:5000/api/updateCandidateById", // for dev env.
      const response = await fetch(
        "https://mantraserp.com/api/updateCandidateById", // for live production
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const result = await response.json();
      if (result?.errorCode === 0) {
        setAadharFile(null);
        setAgreementFile(null);
        setResumeFile(null);
        setphoto(null);
        toast.success(result?.data?.message);
        setShow(false);
        setIsEdit(false);
        getAllCandidates();
        setCandidate({
          firstName: "",
          lastName: "",
          gender: "",
          maritalStatus: "",
          email: "",
          phone: "",
          dob: "",
          highestQualification: "",
          otherQualification: "",
          experience: "",
          sector: "",
          role: "",
          employer: "",
          designation: "",
          yearsOfExperience: "",
          monthsOfExperience: "",
          currentCTC: "",
          expectedCTC: "",
          registeredDate: "",
          source: "",
          personName: "",
          personContact: "",
          residentialAddress: "",
          mailingAddress: "",
          residentialCity: "",
          mailingCity: "",
        });
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };
  // Function to handle row selection
  const handleRowSelect = (row, isSelected) => {
    setSelectedRows([...selectedRows, row]);
  };
  console.log(selectedRows);

  // Function to handle row selection for all rows
  const handleRowSelectAll = (isSelected, rows) => {
    // Update selectedRows state when all rows are selected or deselected
    console.log(rows);
    if (isSelected) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 card border-0">
            <p className="fs-3 fw-normal typed-text">Manage Candidate</p>
          </div>

          <div className="card col-md-6 col-sm-12 border-0">
            <div className="d-flex flex-wrap align-items-center">
              {toggle ? (
                <button
                  type="button"
                  className="btn btn-warning btn-sm mr-2"
                  onClick={handleUpload}
                >
                  <FaCloudUploadAlt color={"black"} size={20} /> Upload
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-warning btn-sm mr-2"
                    onClick={handleButtonClick}
                  >
                    <FaFileDownload color={"black"} size={20} />{" "}
                    <span className="">Import</span>
                  </button>

                  <a
                    href={candidateUpload}
                    download
                    className="btn btn-warning btn-sm mr-2"
                  >
                    <FaFileExport color={"black"} size={20} /> Sample File
                  </a>

                  <button
                    type="button"
                    className="btn btn-warning btn-sm"
                    onClick={() => handleShow()}
                  >
                    <FaAddressCard color={"black"} size={20} /> Add Candidate
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="card col-md-12 border-0">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img src={loadinggif} alt="Loading" />
              </div>
            ) : (
              <ToolkitProvider
                keyField="companyId"
                data={tableData || []} // ✅ Use an empty array if tableData is undefined
                columns={columns}
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                search
              >
                {(props) => (
                  <div>
                    {(data?.role?.roleName === "SuperAdmin" ||
                      data?.role?.roleName === "AdminEM") && (
                      <ExportCSVButton
                        className="btn btn-success btn-sm mb-2 float-end"
                        {...(props.csvProps || {})}
                        filename="MantrasERP_ATS_Candidates_Report.csv"
                      >
                        <SiMicrosoftexcel /> Export Record
                      </ExportCSVButton>
                    )}

                    <div className="table-responsive cus-table">
                      <BootstrapTable
                        {...props.baseProps}
                        selectedRows={selectedRows}
                        bootstrap4
                        keyField="candidateId"
                        data={tableData} // ✅ Ensures it's always an array
                        columns={columns}
                        striped
                        hover
                        condensed
                        filter={filterFactory()}
                        pagination={paginationFactory({ showTotal: true })}
                        selectRow={{
                          mode: "checkbox",
                          clickToSelect: true,
                          onSelect: handleRowSelect,
                          onSelectAll: handleRowSelectAll,
                        }}
                        filterPosition="top"
                      />
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            )}
          </div>
        </div>
      </div>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Modal show={showModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <u>Candidate Registration Form</u>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <h6 className="custom-h6">Personal Details</h6>
          <div className="row">
            <div className="col-md-4">
              <p className="mb-0 fs-6"> First Name</p>
              <input
                placeholder="Enter First Name"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.firstName}
                onChange={(e) => handleOnchange(e.target.value, "firstName")}
              />
            </div>
            <div className="col-md-4">
              <p className="mb-0 fs-6"> Last Name</p>
              <input
                placeholder="Enter Last Name"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.lastName}
                onChange={(e) => handleOnchange(e.target.value, "lastName")}
              />
            </div>

            <div className="col-md-4">
              <p className="mb-0 fs-6"> Gender</p>
              <select
                className="form-control py-0 "
                value={candidate.gender}
                onChange={(e) => handleOnchange(e.target.value, "gender")}
                // //disabled={isEdit}
              >
                <option value="" disabled>
                  Select your status
                </option>

                <option value=">Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6"> Marital Status</p>
              <select
                className="form-control py-0 "
                value={candidate.maritalStatus}
                onChange={(e) =>
                  handleOnchange(e.target.value, "maritalStatus")
                }
                // //disabled={isEdit}
              >
                <option value="" disabled>
                  Select your status
                </option>

                <option value="married">Married</option>
                <option value="unmarried">Unmarried</option>
              </select>
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6"> Email Address</p>
              <input
                placeholder="Enter Email Address"
                className="form-control py-0 "
                type="email"
                // //disabled={isEdit}
                value={candidate.email}
                onChange={(e) => handleOnchange(e.target.value, "email")}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Mobile Number</p>
              <input
                placeholder="Enter Phone Number"
                className="form-control py-0 "
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                required
                value={candidate.phone}
                onChange={(e) => handleOnchange(e.target.value, "phone")}
                // //disabled={isEdit}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">DOB</p>
              <input
                placeholder="Date of Birth"
                className="form-control py-0 "
                type="date"
                // //disabled={isEdit}
                value={candidate.dob}
                onChange={(e) => handleOnchange(e.target.value, "dob")}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Highest Qualification</p>
              <input
                placeholder="Highest Qualification"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.highestQualification}
                onChange={(e) =>
                  handleOnchange(e.target.value, "highestQualification")
                }
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Other Qualification</p>
              <input
                placeholder="Other Qualification"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.otherQualification}
                onChange={(e) =>
                  handleOnchange(e.target.value, "otherQualification")
                }
              />
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Total Experience</p>
              <select
                className="form-control py-0 "
                value={candidate.experience}
                onChange={(e) => handleOnchange(e.target.value, "experience")}
              >
                <option value="">Select</option>
                <option value="0">0</option>
                <option value="0.6">0.6</option>
                {Array.from({ length: 99 }, (_, i) => i + 1).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Sector/Industry</p>
              <select
                placeholder="Sector or Industry"
                className="form-control py-0 "
                value={candidate.sector}
                onChange={(e) => handleOnchange(e.target.value, "sector")}
              >
                <option value="no selection">Select Sector</option>
                <option value="Accounts or CA or CS or Taxation">
                  Accounts or CA or CS or Taxation
                </option>
                <option value="Adhesives">Adhesives</option>
                <option value="Agency or Consulting">
                  Agency or Consulting
                </option>
                <option value="Agriculture">Agriculture</option>
                <option value="Any Official">Any Official</option>
                <option value="Automobile">Automobile</option>
                <option value="Aviation">Aviation</option>
                <option value="Banking">Banking</option>
                <option value="Biotech or R&D or Scientist">
                  Biotech or R&D or Scientist
                </option>
                <option value="BPO">BPO</option>
                <option value="Chemical">Chemical</option>
                <option value="Distribution & Supply Chain">
                  Distribution & Supply Chain
                </option>
                <option value="Education">Education</option>
                <option value="Energy">Energy</option>
                <option value="Engineering Projects">
                  Engineering Projects
                </option>
                <option value="Entertainment">Entertainment</option>
                <option value="Export or Import">Export or Import</option>
                <option value="Finance or Micro Finance">
                  Finance or Micro Finance
                </option>
                <option value="Financial Services">Financial Services</option>
                <option value="FMCG">FMCG</option>
                <option value="Food">Food</option>
                <option value="Glass">Glass</option>
                <option value="Govt. Sector">Govt. Sector</option>
                <option value="Home Furnishing">Home Furnishing</option>
                <option value="Hospital or Healthcare">
                  Hospital or Healthcare
                </option>
                <option value="Hotels or Restaurants">
                  Hotels or Restaurants
                </option>
                <option value="Immigration">Immigration</option>
                <option value="Insurance">Insurance</option>
                <option value="IT Hardware">IT Hardware</option>
                <option value="IT Software">IT Software</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Media and Advertising">
                  Media and Advertising
                </option>
                <option value="Oil & Lubricants">Oil & Lubricants</option>
                <option value="Online Marketing or Digital Marketing">
                  Online Marketing or Digital Marketing
                </option>
                <option value="Paint">Paint</option>
                <option value="Pharmaceutical">Pharmaceutical</option>
                <option value="Plastic or Pet">Plastic or Pet</option>
                <option value="Plywood & Laminates">Plywood & Laminates</option>
                <option value="Publishing & Printing">
                  Publishing & Printing
                </option>
                <option value="Real Estate">Real Estate</option>
                <option value="Retail">Retail</option>
                <option value="Technology">Technology</option>
                <option value="Telecom">Telecom</option>
                <option value="Transport Logistics">
                  Transport or Logistics
                </option>
                <option value="Transport or Tour & Travel">
                  Transport or Tour & Travel
                </option>
                <option value="Warehouse or Inventory">
                  Warehouse or Inventory
                </option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Role & Specialization</p>
              <select
                placeholder="Role & Specialization"
                className="form-control py-0 "
                value={candidate.role}
                onChange={(e) => handleOnchange(e.target.value, "role")}
              >
                <option value="No Selection">
                  Select Role & Specialization
                </option>
                <option value="Account Executive">Account Executive</option>
                <option value="Account Manager">Account Manager</option>
                <option value="Admission Coordinator">
                  Admission Coordinator
                </option>
                <option value="Admin Executive">Admin Executive</option>
                <option value="Admin Manager">Admin Manager</option>
                <option value="Area Sales Manager (ASM)">
                  Area Sales Manager (ASM)
                </option>
                <option value="Article Assistant">Article Assistant</option>
                <option value="Assistant Manager">Assistant Manager</option>
                <option value="Auditor">Auditor</option>
                <option value="Autocad Engineer">Autocad Engineer</option>
                <option value="Backend or Back Office Executive or Assistant Executive">
                  Backend or Back Office Executive or Assistant Executive
                </option>
                <option value="Banquet Manager">Banquet Manager</option>
                <option value="Billing Executives">Billing Executives</option>
                <option value="Block Officer">Block Officer</option>
                <option value="Boiler Operator">Boiler Operator</option>
                <option value="Brand Manager">Brand Manager</option>
                <option value="Business Analyst">Business Analyst</option>
                <option value="Business Development Executive (BDE)">
                  Business Development Executive (BDE)
                </option>
                <option value="Business Development Manager (BDM)">
                  Business Development Manager (BDM)
                </option>
                <option value="Cashier">Cashier</option>
                <option value="Civil Engineer">Civil Engineer</option>
                <option value="Clinical Assistants">Clinical Assistants</option>
                <option value="Collection Executives">
                  Collection Executives
                </option>
                <option value="Commodity Dealer">Commodity Dealer</option>
                <option value="Company Secretary">Company Secretary</option>
                <option value="Computer Operator">Computer Operator</option>
                <option value="Content Writer">Content Writer</option>
                <option value="Counsellor">Counsellor</option>
                <option value="Credit Manager">Credit Manager</option>
                <option value="Customer Care Executive (CCE)">
                  Customer Care Executive (CCE)
                </option>
                <option value="Customer Relationship Officer">
                  Customer Relationship Officer
                </option>
                <option value="Data Analyst">Data Analyst</option>
                <option value="Deputy Manager">Deputy Manager</option>
                <option value="Digital Marketer">Digital Marketer</option>
                <option value="Electrical Engineer">Electrical Engineer</option>
                <option value="Electrician">Electrician</option>
                <option value="Equity Dealer">Equity Dealer</option>
                <option value="Export Manager">Export Manager</option>
                <option value="Fashion Designer">Fashion Designer</option>
                <option value="Finance Executive">Finance Executive</option>
                <option value="Finance Officer">Finance Officer</option>
                <option value="Financial Advisor">Financial Advisor</option>
                <option value="Food & Beverages Executive">
                  Food & Beverages Executive
                </option>
                <option value="Food & Beverages Manager">
                  Food & Beverages Manager
                </option>
                <option value="Front Office Executive">
                  Front Office Executive
                </option>
                <option value="GM/Unit Head">GM/Unit Head</option>
                <option value="Graphics Designer">Graphics Designer</option>
                <option value="Hotel Captain">Hotel Captain</option>
                <option value="Hotel Manager">Hotel Manager</option>
                <option value="HR Executive">HR Executive</option>
                <option value="HR Manager">HR Manager</option>
                <option value="Inventory Manager">Inventory Manager</option>
                <option value="IT Security Coordinator">
                  IT Security Coordinator
                </option>
                <option value="IT Trainer">IT Trainer</option>
                <option value="ITI Fitter">ITI Fitter</option>
                <option value="Lab Technician">Lab Technician</option>
                <option value="Law Officer">Law Officer</option>
                <option value="Liasoning Manager">Liasoning Manager</option>
                <option value="Logistic Manager">Logistic Manager</option>
                <option value="Machine Operator">Machine Operator</option>
                <option value="Maintenance Engineer">
                  Maintenance Engineer
                </option>
                <option value="Mechanical Engineer">Mechanical Engineer</option>
                <option value="Medical Representative">
                  Medical Representative
                </option>
                <option value="Merchandiser">Merchandiser</option>
                <option value="MIS Executive">MIS Executive</option>
                <option value="Networking Manager">Networking Manager</option>
                <option value="Nurse">Nurse</option>
                <option value="Operation Manager">Operation Manager</option>
                <option value="Pathologist">Pathologist</option>
                <option value="Pharmacist">Pharmacist</option>
                <option value="Placement Officer">Placement Officer</option>
                <option value="Plant Planning Manager">
                  Plant Planning Manager
                </option>
                <option value="Plant Supervisor">Plant Supervisor</option>
                <option value="PRO">PRO</option>
                <option value="Production Manager">Production Manager</option>
                <option value="Project Manager or Team Leader">
                  Project Manager or Team Leader
                </option>
                <option value="Purchase Executive">Purchase Executive</option>
                <option value="Quality Analyst">Quality Analyst</option>
                <option value="Quality Control or Process Control">
                  Quality Control or Process Control
                </option>
                <option value="Receptionist">Receptionist</option>
                <option value="Regional Manager">Regional Manager</option>
                <option value="Restaurant Manager">Restaurant Manager</option>
                <option value="Safety Officer">Safety Officer</option>
                <option value="Sales Manager or Marketing Manager">
                  Sales Manager or Marketing Manager
                </option>
                <option value="Sales or Marketing Executive">
                  Sales or Marketing Executive
                </option>
                <option value="SAP or ERP Developer">
                  SAP or ERP Developer
                </option>
                <option value="SEO Executive or Manager">
                  SEO Executive or Manager
                </option>
                <option value="Service Delivery Manager">
                  Service Delivery Manager
                </option>
                <option value="Site Engineer">Site Engineer</option>
                <option value="Social Media Marketing">
                  Social Media Marketing
                </option>
                <option value="Soft Skill Trainer">Soft Skill Trainer</option>
                <option value="Software Developer">Software Developer</option>
                <option value="Store Manager">Store Manager</option>
                <option value="Supervisor">Supervisor</option>
                <option value="T&D Manager">T&D Manager</option>
                <option value="Teacher or Lecturer or Trainer">
                  Teacher or Lecturer or Trainer
                </option>
                <option value="Team Leader">Team Leader</option>
                <option value="Technical Sales">Technical Sales</option>
                <option value="Telecaller">Telecaller</option>
                <option value="Video Editor">Video Editor</option>
                <option value="Vice President">Vice President</option>
                <option value="Waiter or Steward">Waiter or Steward</option>
                <option value="Warehouse Manager">Warehouse Manager</option>
                <option value="Web Designer">Web Designer</option>
                <option value="Web Developer">Web Developer</option>
                <option value="Zonal Manager">Zonal Manager</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="col-md-12 mt-2">
              <p className="mb-0 fs-6">Key Skills</p>
              <input
                placeholder="Role"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.keySkills}
                onChange={(e) => handleOnchange(e.target.value, "keySkills")}
              />
            </div>
            <div className="col-md-12 mt-2">
              <p className="mb-0 fs-6">Languages</p>
              <select
                isMulti
                value={languageOptions.filter(
                  (option) =>
                    candidate.language &&
                    candidate.language.includes(option.value)
                )}
                onChange={handleChange}
                options={languageOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Aadhar Doc</p>
              <input
                className="form-control py-0 "
                type="file"
                accept=".pdf,.doc,.docs"
                name="aadharDoc"
                onChange={(e) => setAadharFile(e.target.files[0])}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Agreement Doc</p>
              <input
                className="form-control py-0 "
                type="file"
                accept=".pdf,.doc,.docs"
                name="agreementDoc"
                onChange={(e) => setAgreementFile(e.target.files[0])}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Resume</p>
              <input
                className="form-control py-0 "
                type="file"
                accept=".pdf,.doc,.docs"
                name="resumeDoc"
                onChange={(e) => setResumeFile(e.target.files[0])}
              />
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Latest Photo</p>
              <input
                className="form-control py-0 "
                type="file"
                accept=".jpg, .jpeg, .png"
                name="photo"
                onChange={(e) => setphoto(e.target.files[0])}
              />
            </div>
          </div>

          <h6 className="custom-h6 mt-5">Experienced Details</h6>
          <div className="row">
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Employer/Company Name</p>
              <input
                placeholder="Company Name"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.employer}
                onChange={(e) => handleOnchange(e.target.value, "employer")}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Designation</p>
              <input
                placeholder="Designation"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.designation}
                onChange={(e) => handleOnchange(e.target.value, "designation")}
              />
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Years Of Experience</p>
              <select
                className="form-control py-0 "
                value={candidate.yearsOfExperience}
                onChange={(e) =>
                  handleOnchange(e.target.value, "yearsOfExperience")
                }
              >
                <option value="">Select</option>
                <option value="0">0</option>
                <option value="0.6">0.6</option>
                {Array.from({ length: 99 }, (_, i) => i + 1).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Months Of Experience</p>
              <select
                className="form-control py-0 "
                value={candidate.monthsOfExperience}
                onChange={(e) =>
                  handleOnchange(e.target.value, "monthsOfExperience")
                }
              >
                <option value="">Select</option>
                <option value="0">0</option>
                {Array.from({ length: 99 }, (_, i) => i + 1).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6"> Current CTC</p>
              <input
                placeholder="Current CTC"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.currentCTC}
                onChange={(e) => handleOnchange(e.target.value, "currentCTC")}
              />
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6"> Expected CTC</p>
              <input
                placeholder="Current CTC"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.expectedCTC}
                onChange={(e) => handleOnchange(e.target.value, "expectedCTC")}
              />
            </div>
          </div>

          <h6 className="custom-h6 mt-5">Contact Information</h6>
          <div className="row">
            <div className="col-md-6">
              <p className="mb-0 fs-6">Registered Date</p>
              <input
                placeholder="Role"
                className="form-control py-0 "
                type="date"
                //disabled={isEdit}
                value={candidate.registeredDate}
                onChange={(e) =>
                  handleOnchange(e.target.value, "registeredDate")
                }
              />
            </div>

            <div className="col-md-6">
              <p className="mb-0 fs-6">Source</p>

              <select
                className="form-control py-0"
                value={candidate.source}
                onChange={(e) => handleOnchange(e.target.value, "source")}
                //disabled={isEdit}
              >
                <option value="">Select Source</option>
                {sourceOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {selectedSource === "Reference" && (
              <div className="d-flex mt-2">
                <div className="form-group col-md-6">
                  <p className="mb-0 fs-6">Person Name</p>
                  <input
                    type="text"
                    className="form-control"
                    value={candidate.personName}
                    placeholder="Refered By"
                    onChange={(e) =>
                      handleOnchange(e.target.value, "personName")
                    }
                  />
                </div>
                <div className="form-group col-md-6">
                  <p className="mb-0 fs-6">Person Contact No.</p>
                  <input
                    type="text"
                    className="form-control"
                    value={candidate.personContact}
                    placeholder="Contact Number"
                    onChange={(e) =>
                      handleOnchange(e.target.value, "personContact")
                    }
                  />
                </div>
              </div>
            )}

            <div className="col-md-6">
              <p className="mb-0 fs-6">Residential Address</p>
              <input
                placeholder="Residential Address"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.residentialAddress}
                onChange={(e) =>
                  handleOnchange(e.target.value, "residentialAddress")
                }
              />
            </div>
            <div className="col-md-6">
              <p className="mb-0 fs-6">Residential City</p>
              <input
                placeholder="Residential City"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.residentialCity}
                onChange={(e) =>
                  handleOnchange(e.target.value, "residentialCity")
                }
              />
            </div>
            <div className="col-md-6 mt-2">
              <p className="mb-0 fs-6">Mailing Address</p>
              <input
                placeholder="Mailing Address"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.mailingAddress}
                onChange={(e) =>
                  handleOnchange(e.target.value, "mailingAddress")
                }
              />
            </div>
            <div className="col-md-6 mt-2">
              <p className="mb-0 fs-6">Mailing City</p>
              <input
                placeholder="Mailing City"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.mailingCity}
                onChange={(e) => handleOnchange(e.target.value, "mailingCity")}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} size="sm">
            Close
          </Button>
          {isEdit ? (
            <Button variant="primary" onClick={handleEditCandidate} size="sm">
              Update Candidate
            </Button>
          ) : (
            <Button variant="primary" onClick={handleAddCandidate} size="sm">
              Add Candidate
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CandidateManagement;
